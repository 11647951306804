// Platform
import { Platform } from 'react-native'

// Notifications
import * as Notifications from 'expo-notifications'

// Clear array of reminders
export async function clearReminders(identifiers) {
	// Clear reminders
	await Promise.all(identifiers.map(id => Notifications.cancelScheduledNotificationAsync(id)))
}

// Clear all reminders
export async function clearAllReminders() {
	// 1. Ignore web
	if(Platform.OS === 'web') return
	// 2. Get all notifications
	const notifications = await Notifications.getAllScheduledNotificationsAsync()
	// 3. Get all reminders
	const reminders = notifications.filter(n => n.identifier.startsWith('@join/reminder/'))
	// 4. Get all identifiers
	const identifiers = reminders.map(n => n.identifier)
	// 5. Clear all reminders
	await clearReminders(identifiers)
}

// Event reminder
export async function reminder(event, id, offset = 0) {
	// 1. Ignore web notifications for now
	if(Platform.OS === 'web') return
	// 2. Ask for notification permission
	const { status } = await Notifications.requestPermissionsAsync()
	// 3. Ignore if no permission granted
	if(status !== 'granted') return
	// 4. Schedule a notification
	const nid = await Notifications.scheduleNotificationAsync({
		identifier: `@join/reminder/${event._id}/${id}`,
		content: {
			title: `${event.title}`,
			body: `${event.description}`,
			data: { type: 'reminder', eid: event._id }
		},
		trigger: { 
			seconds: Math.round((Date.parse(event.start) - Date.now())/1000 -offset)
		},
	})
}
