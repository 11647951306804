import React, {useState,useEffect} from 'react'
import { StyleSheet, View } from 'react-native'

// Modal
import Modal from 'component/Modal'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Input, Button, Icon } from 'react-native-elements'

// Component
export default function TicketPicker(props) {

	// Ticket price
	const [price, setPrice] = useState(props.price)

	// Ticket url
	const [url, setUrl] = useState(props.url)

	// Model
	const [showModal,setShowModal] = useState(false)
	
	// Render component
	return (
		<View style={styles.dateContainer}>
			<View style={styles.buttonContainer}>
				<Button
					type='clear'
					icon={
						<View style={styles.buttonContainer}>
							<Icon name="ticket" size={18} color={price?'white':'gray'} type='entypo'/>
							<Paragraph> {(price)?`${(Math.round(Number(price)*100)/100).toFixed(2)} €`:'-'}</Paragraph>
						</View>
					}
					onPress={_ => setShowModal(true)}
				/>
			</View>

			<Modal 
				visible={showModal}
				animationType='slide'
				onRequestClose={() => {
					// Hide modal
					setShowModal(false)
					// Trigger change event
					if(price || url)
						props.onChange({price:Math.round(Number(price)*100),url})
			}}>
				<View style={styles.modalContainer}>
					<Paragraph style={styles.txt}>Here you can set the price of an event ticket (if applicable). The link should point to a payment page for user to purchase a ticket.</Paragraph>
					<Input
						value={price || ''}
						leftIcon={<Icon name="price-tag" size={16} color="gray" type='entypo'/>}
						rightIcon={<Paragraph>€</Paragraph>}
						placeholder='price'
						keyboardType="numeric" 
						onChange={e => setPrice(e.nativeEvent.text)}/>
					<Input
						value={url || ''}
						leftIcon={<Icon name="link" size={16} color="gray" type='entypo'/>}
						placeholder='https://ticket-link/:id'
						onChange={e => setUrl(e.nativeEvent.text)}/>
				</View>
			</Modal>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	},
	modalContainer:{
		margin:10,
	},
	txt: {
		margin:10,
		color:'grey',
	},
})
