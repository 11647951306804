import React, {useState,useEffect} from 'react'

// Style view
import { Modal, View, Platform } from 'react-native'

// React native elements components
import { Button, Icon } from 'react-native-elements'

// Main wrapper
export default function Component(props) {

	// Style
	const style = {
		backgroundColor: 'black',
		paddingTop:(Platform.OS === 'web')?40:0,
	}
	const propwrap = {
		...props,
		children:undefined
	}
	// Render component
	return (
		<Modal 
			presentationStyle='fullScreen'
			animationType='slide'
			{...propwrap} 
			style={{...style, ...props.style}} children={undefined}>
			<View style={{...style,...props.style,flex:1}}>
				{props.children}
			{ Platform.OS === 'web' && <Button
				containerStyle={{
						position: 'absolute',
						top:0,
						right:0,
						zIndex: 10,
				}}
				type='clear'
				icon={<Icon name='ios-close' size={22} color='white' type='ionicon'/> }
				onPress={props.onRequestClose}
				/>}
			</View>
		</Modal>
	)
}

