import React, {useState,useEffect} from 'react'
import { StyleSheet, View } from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Component
export default function PrivateSwitch(props) {
	const [private_, setPrivate] = useState(false)
	useEffect(()=>setPrivate(props.private), [props.private])
	useEffect(()=>props.onChange(false),[])

	return (
		<View style={styles.dateContainer}>
			<View style={styles.buttonContainer}>
				<Button
					type='clear'
					icon={
						<View style={styles.buttonContainer}>
							{ private_ && <>
								<Icon name="eye-off" size={18} type='material-community'/>
								<Paragraph> private </Paragraph>
							</>}
							{  !private_ && <>
								<Icon name="eye" size={18} type='material-community'/>
								<Paragraph> public </Paragraph>
							</>}
						</View>
					}
					onPress={() => {
						props.onChange(!private_)
						setPrivate(!private_)
					}}/>
			</View>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
		alignContent: 'center',
		alignItems:'center',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	}
})
