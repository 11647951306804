import React, {useState,useEffect} from 'react'
import { Platform, StyleSheet, View, FlatList, ActivityIndicator} from 'react-native'

// React native elements
import { Avatar, Icon } from 'react-native-elements'

// React native tab view
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

// Containers
import { Wrapper } from 'style/containers'

// Typography
import { Paragraph} from 'style/typography'

// Image picker
import * as ImagePicker from 'expo-image-picker'

// Image manipulator
import { manipulateAsync, SaveFormat} from 'expo-image-manipulator';

// User hook
import { useUser, useAvatar } from 'hook/user'

// Events hook
import { useUserCreatedEvents, useUserGoingEvents, useUserSavedEvents, useUserLikedEvents } from 'hook/events'

// Header menu component
import HeaderMenu from 'component/HeaderMenu'

// Event card
import EventCard from 'component/EventCard'

// Import util
import { dataURLToBlob } from 'helper/util'

function Cards({events, user, refetch, loading, navigation}) {
	// Render component
	return (<FlatList 
		data={events}
		keyExtractor={event => event._id}
		renderItem={({item}) => (<EventCard
			user={user}
			event={item}
			liked={user?.likes.includes(item?._id)}
			onPress={ event => {navigation.navigate('event',{id:event._id})}}
	/>)}
		numColumns={2}
		onRefresh={() => refetch()}
		refreshing={loading}
	/>)
}

// Profile screen
export default function Profile({navigation, route, ...props}) {

	// User id
	const [id, setId] = useState(route?.params?.id || props?.id)

	// Track user id
	useEffect(() => setId(route?.params?.id || props?.id), [route?.params?.id, props?.id])

	// Set navigation options
	useEffect(() => {
		// Set header
		navigation?.setOptions({
			title:`${user?.firstname} ${user?.lastname}`,
			headerRight: () => (<HeaderMenu navigation={navigation}/>)
		})
	},[user])

	// User
	const { data:user, isLoading:userLoading } = useUser(id)

	// User created events
	const { data:created, isLoading:createdLoading, refetch:createdRefetch } = useUserCreatedEvents()

	// User events is going to
	const { data:going, isLoading:goingLoading, refetch:goingRefetch } = useUserGoingEvents()

	// User saved events
	const { data:saved, isLoading:savedLoading, refetch:savedRefetch } = useUserSavedEvents()

	// User saved events
	const { data:liked, isLoading:likedLoading, refetch:likedRefetch } = useUserLikedEvents()

	// Avatar mutation hook
	const {mutateAsync:setAvatar} = useAvatar()

	// Pick the avatar
	async function pickAvatar() {
		// Start image picker
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Image,
			allowsEditing:true,
			aspect: [4,4],
			quality:1,
			includeBase64:true,
		})

		// If cancelled stop right here
		if(result.cancelled) return

		// Resize image
		const image = await manipulateAsync(result.uri, [{resize:{height: 200}}], {compress:0.75, format: SaveFormat.JPEG})

		// Send image to backend
		if(Platform.OS === 'web') {
			// Construct blob
			const blob = dataURLToBlob(image.uri)
			// Send base64 image
			await setAvatar(blob)
		} else {
			await setAvatar({
				uri: image.uri,
				type:'image/jpeg',
				name:'avatar.jpeg'
			})
		}
	}

	/*
			<View style={styles.col}>
				<View style={styles.row}>
					<Paragraph> <Icon size={11} name="flag"      type='feather' /> {user?.events?.length} </Paragraph>
					<Paragraph> <Icon size={11} name="user-plus" type='feather' color='green' /> {user?.going?.length} </Paragraph>
					<Paragraph> <Icon size={11} name="star"      type='feather' color='yellow'/> {user?.saves?.length} </Paragraph>
					<Paragraph> <Icon size={11} name="heart"     type='feather' color='purple'/> {user?.likes?.length} </Paragraph>
				</View>
			</View>
	*/

	// Profile part
	const profile = (
		<View style={{marginVertical:20}}>
			<View style={styles.col}>
				<Avatar
					lo
					rounded
					size={120}
					source={user?.avatar && {uri:user.avatar}}
					onPress={pickAvatar}
					title={user?.avatar?'':user?.firstname[0].toUpperCase()+user?.lastname[0].toUpperCase()}
					>
				</Avatar>
				<Paragraph>{user?.firstname} {user?.lastname}</Paragraph>
			</View>
		</View>
	)

	// Active page index
	const [index, setIndex] = useState(0)

	// Page routes
	const routes = [
	{ key: 'created', title:`${user?.events?.length || '-'}` },
	{ key: 'going',   title:`${user?.going?.length  || '-'}`},
	{ key: 'saved',   title:`${user?.saves?.length  || '-'}`},
	{ key: 'liked',   title:`${user?.likes?.length  || '-'}`},
	]

	// Render screen
	return (
		<Wrapper style={{flex:1}}>
			{profile}
			<TabView
				navigationState={{index, routes}}
				onIndexChange={setIndex}
				// renderScene={renderScene}
				renderScene={({route}) => {
					if(route.key === 'created')
						return (<Cards 
						events={created} 
						user={user} 
						refetch={createdRefetch} 
						loading={createdLoading} 
						navigation={navigation} />)
					if(route.key === 'going')
						return (<Cards 
						events={going} 
						user={user} 
						refetch={goingRefetch}
						loading={goingLoading}
						navigation={navigation} />)
					if(route.key === 'saved')
						return (<Cards 
						events={saved} 
						user={user} 
						refetch={savedRefetch}
						loading={savedLoading}
						navigation={navigation} />)
					if(route.key === 'liked')
						return (<Cards 
						events={liked} 
						user={user} 
						refetch={likedRefetch}
						loading={likedLoading}
						navigation={navigation} />)
				}}
				renderTabBar={props => <TabBar
					{...props}
					indicatorStyle={{backgroundColor:'white'}}
					style={{backgroundColor:'transparent'}}
					renderIcon={props => {
						// Icon size
						const size = 18
						// Extract props
						const {route, focused} = props
						// User created events tab
						if(route.key === 'created')
							return <Icon size={size} name='flag' type='feather' color={focused?'white':'grey'}/>
						// Events user is going to tab
						if(route.key === 'going')
							return <Icon size={size} name='user-check' type='feather' color={focused?'lightgreen':'grey'} />
						// Events user saved tab
						if(route.key === 'saved')
							return <Icon size={size} name='star' type='feather' color={focused?'yellow':'grey'}/>
						// Events user liked tab
						if(route.key === 'liked')
							return <Icon size={size} name='heart' type='feather' color={focused?'pink':'grey'}/>
					}}
				/>}
			/>
		</Wrapper>
	)
}

// Styles
const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
	},
	col: {
		flexDirection: 'column',
		alignItems:'center',
		alignContent:'center',
	},
	flatlist: {
		flex:1,
		flexGrow:1,
	}
})
