// React
import React, {useState,useEffect,useRef} from 'react'

// Typography
import { Paragraph } from 'style/typography'

// React native
import { Platform, Linking, StyleSheet, View } from 'react-native'

// Modal
import Modal from 'component/Modal'

// Button
import { Button, Icon } from 'react-native-elements'

// React native maps
import MapView, {Marker, UrlTile} from 'react-native-maps'

// Dark map style
import darkmap from 'resource/dark.map'

// Hook to get device location
import {useLocation} from 'hook/location'

// Distance calculator
import {getDistance} from 'geolib'

// Component
export default function LocationButton(props) {

	// Mount ref
	const refContainer = useRef(false)
	
	// Event location
	const [location, setLocation] = useState(props.location || [0,0])

	// Track location
	useEffect(()=> {if(refContainer.current) setLocation(props.location || [0,0])},[props.location])

	// Device location
	const {data:device} = useLocation()

	// Distance between user and event
	const [distance, setDistance] = useState(undefined)

	// Track distance
	useEffect(() => {
		if(refContainer.current) setDistance( device && getDistance(
			{longitude:device?.coords.longitude,latitude:device?.coords.latitude},
			{longitude:location[0],latitude:location[1]}
		)/1000)
	},[device,location])

	// Generate the region object
	function getRegion(device,location) {
		return {
			longitude: (device?.coords?.longitude + location[0])/2 || location[0],
			latitude:  (device?.coords?.latitude  + location[1])/2 || location[1],
			longitudeDelta: (Math.abs(device?.coords?.longitude - location[0]))*1.4 || 0.02,
			latitudeDelta: (Math.abs(device?.coords?.latitude   - location[1]))*1.4 || 0.02,
		}
	}

	// Region
	const [region, setRegion] = useState(getRegion(device,location))

	// Track region
	useEffect( () => {
		if(refContainer.current) setRegion(getRegion(device,location))
	}, [device,location])

	// Disabled
	const [disabled,setDisabled] = useState()

	// Track disabled
	useEffect(()=>{if(refContainer.current) setDisabled(props.disabled)}, [props.disabled])

	// Modal shown or not
	const [show, setShow] = useState(false)

	// Render component
	return (
		<View ref={refContainer}>
			<View style={styles.container}>
				<Button
				disabled={disabled}
				onPress={() => {
						if(Platform.OS === 'web')
							Linking.openURL(`https://maps.google.com/?q=${location[1]},${location[0]}`)
						else
							setShow(true)
				}}
				type='clear'
				icon={<Icon name='location' size={24} color={disabled && 'grey'} type='ionicon'/> }
				/>
				<Paragraph>{distance?`${distance.toFixed(2)} km`:'? km'}</Paragraph>
				</View>
			{ (Platform.OS !== 'web') && 
			<Modal
				visible={show} 
				onRequestClose={ () => setShow(false) }
				>
				<MapView 
					provider={null}
					style={{flex:1}}
					customMapStyle={darkmap}
					initialRegion={region}
					loadingBackgroundColor='black'
					>
					<UrlTile
							urlTemplate={'http://c.tile.openstreetmap.org/{z}/{x}/{y}.png'}
							maximumZ={19}
					/>
					{device && <Marker coordinate={device?.coords} > 
						<Icon name="person-pin" size={32} type='material'/>
					</Marker>}
					<Marker coordinate={{longitude:location[0],latitude:location[1]}} >
						<Icon name="location-pin" size={32} type='material'/>
					</Marker>
				</MapView>
			</Modal>}
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignContent: 'center',
		alignItems:'center',
	},
	map: {
		flex:1,
		backgroundColor:'black',
	},
	mapPlaceHolder: {
		position:'absolute',
		top:0,
		left:0,
		bottom:0,
		right:0,
	}
})
