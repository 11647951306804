import React, {useState, useEffect, useRef} from 'react'
import { StyleSheet, Text, Alert, ScrollView, View } from 'react-native'
import { Input, Button, Icon, CheckBox } from 'react-native-elements'

// Containers
import { Wrapper } from 'style/containers'

// Menu in the header
import HeaderMenu from 'component/HeaderMenu'

// Code verifier component
import CodeVerifier from 'component/CodeVerifier'

// Authentication hooks
import {useRegisterRequest, useRegisterVerify} from 'hook/authentication'

// Render area
import { useRenderArea } from 'hook/utilities'

// Icons
import { Entypo} from '@expo/vector-icons';

// Failure alert
function alertFailed(message) {
	Alert.alert('Failure', message)
}

// Request user account page
function Request({navigation, style, onRequest}) {
	// User email
	const [email, setEmail] = useState('')

	// User password
	const [password, setPassword] = useState('')

	// Repeated user password
	const [repeat, setRepeat] = useState("")

	// Firstname
	const [firstname, setFirstname] = useState('')
	
	// Lastname
	const [lastname, setLastname] = useState('')

	// Agree
	const [agree, setAgree] = useState(false)

	// Validity
	const [valid, setValid] = useState(false)

	// Validate email adres NOTE: TO COMPLEX, USE REGISTRATION MAIL FOR VALIDATION!!
	const validateEmail = email => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}

	// Track validity
	useEffect(() => 
		setValid(
			agree && 
			email && 
			validateEmail(email) &&
			firstname && 
			lastname && 
			password && 
			password === repeat
	)
	,[email, password, repeat, firstname, lastname, agree])

	// Register request hook
	const { mutate:registerRequest, data:registrationCode, isLoading: loading } = useRegisterRequest({ 
		onSuccess:() => onRequest()
	})

	// Render request page
	return (
		<Wrapper style={style}>
			<ScrollView>
				<Input label='Email'
					placeholder='email'
					leftIcon={<Icon name="mail" size={18} color="gray" type='entypo' />}
					onChangeText={text => setEmail(text)}
				/>				
				<Input label='Firstname'
					placeholder='firstname'
					leftIcon={<Icon name="edit" size={18} color="gray" type='entypo'/>}
					onChangeText={text => setFirstname(text)}
				/>
				<Input label='Lastname'
					placeholder='lastname'
					leftIcon={<Icon name="edit" size={18} color="gray" type='entypo'/>}
					onChangeText={text => setLastname(text)}
				/>
				<Input label='Password'
					placeholder='password'
					leftIcon={<Icon name="lock" size={18} color="gray" type='entypo'/>}
					onChangeText={text => setPassword(text)}
					secureTextEntry={true}
				/>
				<Input label='Repeat password' 
					placeholder='password'
					leftIcon={<Icon name="lock" size={18} color="gray" type='entypo'/>}
					onChangeText={text => setRepeat(text)}
					secureTextEntry={true}
				/>
				<View style={{flexDirection:'row'}}>
					{/* age picker */}
				</View>
				<Button 
					title='Send' 
					type='outline'
					loading = { loading }
					disabled={!valid}
					onPress={ async () => registerRequest({email,password,firstname,lastname})}
				/>
				<View style={{flex:0, flexDirection:'column', alignItems:'center'}}>
					<CheckBox 
						title='I agree to the terms and conditions'
						checked={agree}
						onPress={() => setAgree(!agree)}
					/>
					<Button 
						type='clear'
						title='terms and conditions'
						style={{color:'lightblue'}} 
						onPress={() => navigation.navigate('tos')}
						> 
					</Button>
				</View>
				<Text>{registrationCode}</Text>
			</ScrollView>
		</Wrapper>
	)
}

// Verify user account page
function Verify({navigation, style}) {
	// Register verify hook
	const {mutateAsync:registerVerify} = useRegisterVerify()
	// Render verify page
	return (
		<Wrapper style={style}>
			<CodeVerifier 
				placeholder="Registration verification code"
				onVerify={ async code => {
					// Verify code
					const done = await registerVerify({code})
					// Check if successful
					if(done) {
						navigation?.popToTop()
						return true
					}
					return false
			}}/>
			<Text>We have send you an email with an activation code.</Text>
			<Text> </Text>
			<Text>Scan the QR code or copy your activation code to finish registration.</Text>
		</Wrapper>
	)
}

// Register user account screen
function Register({navigation}) {

	// Scroll view reference
	const refScrollView = useRef()

	// Get the dimensions of the area to render on
	const {width,height} = useRenderArea()

	// Navigation options
	useEffect(()=>{
		navigation?.setOptions({
			title: 'Register an account',
			headerRight: () => (<HeaderMenu navigation={navigation}/>)
		})
		},[])

	// Render register screen
	return (
		<ScrollView pagingEnabled={true} horizontal={true} style={styles.ScrollView} ref={refScrollView}>
			<Request navigation={navigation} style={{width,height}} onRequest={() => {
				refScrollView.current.scrollTo({x:width})
			}}/>
			<Verify style={{width,height}} navigation={navigation}/>
		</ScrollView>
	)
}

// Export view
export default Register

// Styles
const styles = StyleSheet.create({})
