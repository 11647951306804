// React
import { useState } from 'react'

// React query
import { useQuery, useMutation } from 'react-query'

// Location services
import * as Location from 'expo-location'

// Axios
import axios from 'axios'

// Core api calls
import apicore from 'api/core'

// Import api calls
import api from 'api'

// Authentication hooks
import { useLoggedin } from 'hook/authentication'

// Ask for background permission
export function useBackgroundPermissions(options) {
	// Construct query
	return useQuery(Location.getBackgroundPermissionsAsync, {
		...options
	})
}

// Get location
async function fetchLocation() {
	// Derive location from device
	try {
		// Ask location permission
		Location.requestForegroundPermissionsAsync()
		// Extract device location
		const location = await Location.getLastKnownPositionAsync()
		// Return location
		if(location) 
			return location
	} catch(err) {}
	// Derive location using geoip
	try {
		// Get public ip
		const {ip} = (await axios.get('https://api.ipify.org?format=json')).data
		// Use geoip to derive location
		const position = (await apicore.post('/service/location',{ip})).data
		// Construct location
		const location = position?{coords:position}:undefined
		// Return location
		if(location)
			return location
	} catch(err) {}
	// Use a public service
	try {
		// Get position
		const {latitude,longitude} = (await axios.get('https://api.freegeoip.app/json/?apikey=118157c0-8f56-11ec-a673-a51cdc0f2e04')).data
		// Construct location
		const location = (latitude && longitude)?{coords:{latitude,longitude}}:undefined
		// Return location
		if(location)
			return location
	} catch(err) {
		console.log(err)
	}
	// If all else fails
	return undefined
}

// Use location
export function useLocation(options) {
	// Check if user loggedin
	const {data:loggedin, isFetching} = useLoggedin()
	// Construct query
	return useQuery(['location'], fetchLocation, {
		placeholderData: null,
		staleTime: 1*60*1000,
		...options
	})
}

// Search for place convert to location
export function useGeocodeForward(options) {
	// Construct mutation
	return useMutation(api.fetchGeocodeForward, options)
}

// Convert location to a place
export function useGeocodeBackward(options) {
	// Construct mutation
	return useMutation(api.fetchGeocodeBackward, options)
}
