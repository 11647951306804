import React,{useState,useEffect} from 'react'
import { StyleSheet, Text, View, FlatList, ActivityIndicator} from 'react-native'

// Containers
import { Wrapper } from 'style/containers'

// Components
import HeaderMenu from 'component/HeaderMenu'

// Event card
import EventCard from 'component/EventCard'

// Events hook
import {useUserSavedEvents} from 'hook/events'

// User hook
import {useUser} from 'hook/user'

// View
function Saved({navigation}) {

	// User data
	const { data:user } = useUser()

	// User saved events
	const { data:events, refetch:eventsRefetch, isFetching} = useUserSavedEvents()

	// Set header
	useEffect(()=>{
		navigation?.setOptions({
			headerShown: true,
			title: 'Saved events',
		})
		},[])

	// Render component
	return (
		<Wrapper style={{flex:1}}>
			<FlatList style={styles.flatlist}
				showsVerticalScrollIndicator
				data={events}
				keyExtractor={event => event?._id}
				renderItem={({item}) => (<EventCard 
					user ={user}
					event={item}
					onPress={ event => {navigation.navigate('event',{id:event?._id})}}
				/>)}
				numColumns={2}
				onRefresh={() => {eventsRefetch()}}
				refreshing={isFetching}
			/>
		</Wrapper>
	)
}

// Export view
export default Saved

// Styles
const styles = StyleSheet.create({
	flatlist: {
		// flex: 1,
	},
});
