// Import core
import api from './core'

// Open a new feed
export async function fetchFeedOpen(search,latitude,longitude) {
	// Fetch the first cursor position
	return (await api.post(`/feed/open`, {search, latitude, longitude})).data.next
}

// Get next on feed
export async function fetchFeedPage(next,size) {
	return (await api.post(`/feed/page`, {next}, {params:{size}})).data
}

// Close the feed
export async function fetchFeedClose(next) {
	return (await api.post(`/feed/close`, {next})).data
}
