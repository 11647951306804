// React
import React, {useState,useEffect} from 'react'

// React native
import { StyleSheet, View, ScrollView } from 'react-native'

// Modal
import Modal from 'component/Modal'

// React native elements
import { Input, Button, Icon, Avatar, ListItem } from 'react-native-elements'

// Typography
import { Paragraph } from 'style/typography'

// Search users hook
import { useUserSearch } from 'hook/user'

// Host Input
function HostInput(props) {
	// selected host
	const [host, setHost] = useState({ext:''})
	// Show search bar
	const [show, setShow] = useState(false)
	// Search hook
	const { mutate:search, data:results = []} = useUserSearch()

	// Render component
	return (
		<View>
			<Input 
				label="Add host" 
				value={host?.user && `${host?.user?.firstname} ${host?.user?.lastname}` || host?.ext}
				placeholder='House of Latin'
				onChange={e => {
					setHost({ext:e.nativeEvent.text})
					search(e.nativeEvent.text)
				}}
				onFocus={()=>setShow(true)}
				rightIcon={<View style={{flexDirection:'row'}}>
					{show && results.length > 0 && <Button
						type='clear' 
						icon={{
							name:'close', 
							type:'material', 
							size:20}}
						onPress={_ => setShow(false)}
					/>}
					<Button
						type='clear' 
						disabled={!(host?.user || host?.ext)}
						icon={{
							name:'person-add', 
							type:'material', 
							size:20}}
						onPress={_ => {
							props.onAdd(host)
							setHost({ext:''})
						}}
					/>
				</View>}
			/>
			<ScrollView>
				{show && results.map(user => 
					<ListItem 
						key={user._id}
							onPress={() => {
							setHost({user})
						}}
					>
						<Avatar
							rounded 
							source={{uri:user?.avatar}}
						/>
						<ListItem.Content>
							<ListItem.Title>{`${user.firstname} ${user.lastname}`}</ListItem.Title>
						</ListItem.Content>
					</ListItem> 
				)}
			</ScrollView>
		</View>
	)
}

// Component
export default function HostPicker(props) {

	// Party host
	const [hosts, setHosts] = useState(props.hosts || [])

	// Track party host
	useEffect(()=>setHosts(props.hosts || []),[props.hosts])

	// Show modal
	const [showModal,setShowModal] = useState(false)

	// Render component
	return (
		<View style={styles.dateContainer}>


			<View style={styles.buttonContainer}>
				<Button
					type='clear'
					icon={
						<View style={styles.buttonContainer}>
							<Icon name='person-outline' type='ionicons' size={18} color={hosts?'white':'grey'} />
							<Paragraph> {hosts.length || '-'}</Paragraph>
						</View>
					}
					onPress={_ => setShowModal(true)}
				/>
			</View>

			<Modal
				visible={showModal} 
				animationType='slide'
				onRequestClose={() => setShowModal(false)}
			>
				<View style={styles.modalContainer}>
					<HostInput
						onAdd={host => props.onAdd([...hosts,host])}
					/>
					{hosts.map((host,idx) => 
						<ListItem key={host.ext || host.user._id}>
							<Avatar
								rounded 
								source={{uri:host?.user?.avatar}}
							/>
							<ListItem.Content>
								<ListItem.Title>{host.ext || `${host.user.firstname} ${host.user.lastname}`}</ListItem.Title>
							</ListItem.Content>
							<Icon 
								name='close' 
								type='material' 
								color='red'
								size={18} 
								onPress={()=>setHosts(hosts.filter(h=>h!=host))}
							/>
						</ListItem>
					)}
				</View>
			</Modal>
		</View>
	)
}


					// <Paragraph style={styles.txt}>Name the person, organisation or user who is hosting the event.</Paragraph>
					// <Input label="Host" 
					// 	value={host}
					// 	placeholder='Jack'
					// 	onChange={e=>props.onChange(e.nativeEvent.text)}
					// />
					// <Paragraph style={styles.txt}>* If the name matches with a user he will be shown in your event.</Paragraph>

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
		alignContent: 'center',
		alignItems:'center',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	},
	modalContainer:{
		margin:10,
	},
	txt: {
		margin:10,
		color:'grey',
	}
})
