// React Core
import React, {useState, useEffect} from 'react'

// React Native
import { StyleSheet } from 'react-native'

// React Native Elements
import { Button, Icon } from 'react-native-elements'

// Hooks
import { useLogout, useLoggedin } from 'hook/authentication'

// Menus
import { Menu, MenuItem} from 'react-native-material-menu'

// Menu definition
function HeaderMenu({navigation, route}) {
	// Hide/Show dropdown menu state
	const [visible, setVisible] = useState(false);
	// Hide the menu
	const hideMenu = () => setVisible(false);
	// Show the menu
	const showMenu = () => setVisible(true);
	// Logout hook
	const {data:loggedin} = useLoggedin()
	// Login hook
	const {mutate:logout} = useLogout()

	// Render component
	return (
			<Menu
				style={{backgroundColor:'black', color:'white'}}
				visible={visible}
				anchor={
					<Button
						type='clear'
						icon={<Icon name="dots-vertical" size={24} type='material-community'/>}
						onPress={showMenu}
					/>
				}
				onRequestClose={hideMenu}
			>
			<MenuItem 
				style={{backgroundColor:'black', color:'white'}}
				textStyle={{backgroundColor:'black', color:'white'}}
				onPress={ () => {
					navigation.navigate('settings')
					hideMenu()
				}}> Settings </MenuItem>
			<MenuItem 
				style={{backgroundColor:'black', color:'white'}}
				textStyle={{backgroundColor:'black', color:'white'}}
				onPress={ () => {
					navigation.navigate('permissions')
					hideMenu()
				}}> Permissions </MenuItem>
			<MenuItem 
				style={{backgroundColor:'black', color:'white'}}
				textStyle={{backgroundColor:'black', color:'white'}}
				onPress={ () => {
					navigation.navigate('tos')
					hideMenu()
				}}> Terms and conditions </MenuItem>
			<MenuItem 
				style={{backgroundColor:'black', color:'white'}}
				textStyle={{backgroundColor:'black', color:'white'}}
				onPress={ () => {
				if(loggedin)
					logout()
				else
					navigation.navigate('login')
				hideMenu()
			}}> {loggedin?'Logout':'Login'} </MenuItem>
			</Menu>
	)	
}

// Export menu
export default HeaderMenu

// Styles
const styles = StyleSheet.create({})
