import React, {useState,useEffect} from 'react'

import {StyleSheet,View,Text} from 'react-native'

// Title
export function Title(props) {
	// Style
	const style = {
		fontSize: 32,
		color: 'white',
	}
	// Render component
	return (
		<Text {...props} style={{...style, ...props.style}}></Text>
	)
}

// Subtitle
export function Subtitle(props) {
	// Style
	const style = {
		color:'white',
		fontSize: 20,
	}
	// Render component
	return (
		<Text {...props} style={{...style, ...props.style}}></Text>
	)
}

// Paragraph
export function Paragraph(props) {
	// Style
	const style = {
		color:'white',
		fontSize: 14
	}
	// Render component
	return (
		<Text {...props} style={{...style, ...props.style}}></Text>
	)
}
