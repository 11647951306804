import { Alert } from 'react-native'
import Toast from 'react-native-toast-message'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import AsyncStorage from '@react-native-async-storage/async-storage'

// Import api
import api from 'api'

// Get api url
async function getApiUrl() {
	return await AsyncStorage.getItem('@join/auth/api')
}

// Use api url
export function useGetApi(options) {
	return useQuery('api', getApiUrl, options)
}

// Set api url
async function setApiUrl(url) {
	return await AsyncStorage.setItem('@join/auth/api', url)
}

// Change the api url
export function useSetApi(options) {
	// We need to logout when switching
	const {mutateAsync:logout} = useLogout()
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(setApiUrl,{
		onMutate: async () => {
			try { await logout() } catch(err) {}
		},
		onSuccess:() => queryClient.invalidateQueries('api'),
		...options
	})
}

// Clear api url
async function clearApiUrl() {
	return await AsyncStorage.removeItem('@join/auth/api')
}

// Clear the api url to default
export function useClearApi(options) {
	// We need to logout when switching
	const {mutateAsync:logout} = useLogout()
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(clearApiUrl,{
		onMutate: async () => {
			try { await logout() } catch(err) {}
		},
		onSuccess:() => queryClient.invalidateQueries('api'),
		...options
	})
}

// Get web url
async function getWebUrl() {
	return await AsyncStorage.getItem('@join/auth/web')
}

// Use web url
export function useWebUrl(options) {
	return useQuery('web', getWebUrl,options)
}

// Set web url
async function setWebUrl(url) {
	return await AsyncStorage.setItem('@join/auth/web', url)
}

// Change we url
export function useSetWebUrl(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(setWebUrl, {
		onSuccess: () => queryClient.invalidateQueries('web'),
		...options
	})
}

// Use register request
export function useRegisterRequest(options) {
	return useMutation(api.fetchRegisterRequest, {
		onSuccess: () => Toast.show({
			type: 'success',
			text1: 'Check your email',
			text2: 'Check your mail to activate your account',
		}),
		...options
	})
}

// Use register verify
export function useRegisterVerify(options) {
	return useMutation(api.fetchRegisterVerify, {
		onSuccess: () => Toast.show({
			type: 'success',
			text1: 'User account activated',
			text2: 'User account has been activated, you can now login.',
		}),
		...options
	})
}

// Fetch the token
async function getToken({email,password}) {
	// Get token from storage
	var token  = await AsyncStorage.getItem('@join/auth/token')
	var expire = await AsyncStorage.getItem('@join/auth/expire')
	// Check if token is valid
	if(!token || Date.now() > Date.parse(expire)) {
		// Fetch valid token from server
		var {token,expire} = await api.fetchToken({email,password})
		console.log(token,expire)
		// Push token to storage
		await AsyncStorage.setItem('@join/auth/token',  token)
		await AsyncStorage.setItem('@join/auth/expire', expire)
	}
	// Return token
	return {token,expire}
}

// Use the login
export function useLogin(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(getToken, {
		// mutationKey:'auth',
		// When fetch token succeeds
		onSuccess: () => {
			// Set query data
			queryClient.setQueryData('loggedin', true)
			// Invalidate logged in
			queryClient.invalidateQueries('loggedin')
		},
		...options
	})
}

// Check if token is in storage and valid
async function validateToken() {
	// Get token from storage
	var token  = await AsyncStorage.getItem('@join/auth/token')
	// Return valid token
	return !!token
}

// Check if loggedin
export function useLoggedin(options) {
	// Create query
	return useQuery('loggedin', validateToken, {
		staleTime: 1*1000,
		refetchInterval:1000*1,
		notifyOnChangeProps:['data'],
		...options
	})
}

// clear token
async function clearToken() {
	// Clear token from frontend
	await AsyncStorage.removeItem('@join/auth/token')
	await AsyncStorage.removeItem('@join/auth/expire')
	// Clear token from backend
	api.clearToken()
}

// Logout user
export function useLogout(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation('logout', clearToken, {
		onSettled: () => {
			// Set loggedin cache
			queryClient.setQueryData('loggedin',false)
			// Clear cache, invalidate all queries
			queryClient.clear()
		},
		...options
	})
}

// Use password reset request
export function useResetRequest(options) {
	return useMutation(api.fetchResetRequest, {
		...options
	})
}

// Use password reset verify
export function useResetVerify(options) {
	return useMutation(api.fetchResetVerify, {
		onSuccess: () => Toast.show({
			type: 'success',
			text1: 'Password reset',
			text2: 'Password has been successfully reset.',
		}),
		...options
	})
}
