// import { Buffer } from "buffer"

// // Base 64 string to binary Blob
// export function base64ToBlob(base64, type) {
// 	// Convert to bytes
// 	const byteCharacters = atob(base64)
// 	// Create binary string
// 	var binary = new Uint8Array(byteCharacters.length)
// 	// Encode	
// 	for (var i = 0; i < byteCharacters.length; i++)
// 	binary[i] = byteCharacters.charCodeAt(i)
// 	// Return blob
// 	return new Blob([binary], {type})
// }

// data URL to binary Blob
// export function dataurlToBlob(dataurl) {
// 	// Extract mimetype and base64 data
// 	const [,type,base64] = dataurl.match(/data:(.*);base64,(.*)/)
// 	// Return blob
// 	return base64ToBlob(base64,type)
// }

// // Base 64 string to binary Blob
// export async function base64ToBlob(base64, type) {
// 	console.log('trace 1',`data:${type};base64,${base64}` )
// 	return await fetch(`data:${type};base64,${base64}`).then(res => res.blob())
// }

// // data URL to binary Blob
// export async function dataurlToBlob(dataurl) {
// 	console.log('trace 2',dataurl)
// 	return await fetch(dataurl).then(res => res.blob())
// }

// Buffer object
import { Buffer } from 'buffer'

// Base 64 string to binary Blob
export function base64ToBlob(base64, type) {
	// Construct buffer
	const buffer = Buffer.from(base64, 'base64')
	// Return blob
	var blob = new Blob([buffer], {type})
	return blob
}

// data URL to binary Blob
export function dataURLToBlob(dataURL) {
	// Extract mimetype and base64 data
	const [,type,base64] = dataURL.match(/data:(.*);base64,(.*)/)
	// Return blob
	return base64ToBlob(base64,type)
}
