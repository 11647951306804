import React, {useLayoutEffect} from 'react'
import { StyleSheet, Text, View} from 'react-native'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Containers
import { Wrapper } from 'style/containers'

// Typography
import { Title, Subtitle, Paragraph } from 'style/typography'

// Location hooks
import { useBackgroundPermissions } from 'hook/location'

// Location services
import * as Location from 'expo-location'

// Ask for location permission
function askLocationPermission() {
	// Ask for foreground location permission
	Location.requestBackgroundPermissionsAsync()
	// Ask for background location permission
	Location.requestForegroundPermissionsAsync()
}

// Component
export default function Permissions({navigation}) {

	// Location permission hook
	// const [status, requestPermission] = useHasLocationPermissions()

	// Set header
	useLayoutEffect(() =>
		navigation?.setOptions({
			title: 'Permissions'
		})
	,[])

	const {data,isLoading} = useBackgroundPermissions()

	console.log('permission status', data, isLoading)

	const status = false


	// Render component
	return (
		<Wrapper>
			<Subtitle> Location </Subtitle>
			<Paragraph>
				We need permissions to access your location in order to find interesting events near you.
			</Paragraph>
			<Button 
				type='outline' 
				title='Grand location permission'
				loading={isLoading}
				icon={<Icon 
					name={status?.granted?'check':'cross'} 
					color={status?.granted?'green':'red'} 
					type='entypo' 
				/>}
				disabled={ status?.granted }
				onPress={ async () => await requestPermission() }
			/>
			<Subtitle> Notifications </Subtitle>
			<Paragraph>
				We need permissions to send you notifications in order to inform you of new events.
			</Paragraph>
			<Button 
				type='outline' 
				title='Grand notification permission'
				icon={<Icon 
					name={status?.granted?'check':'cross'} 
					color={status?.granted?'green':'red'} 
					type='entypo' 
				/>}
				disabled={ status?.granted }
				onPress={ async () => await requestPermission() }
			/>
		</Wrapper>
	)
}

// Styles
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor:'black'
	},
})
