// Format event address
export function formatAddress(address) {
	// Road
	const p1 = address.road || address.highway || ''
	// House number
	const p2 = address.house_number || ''
	// Post code
	const p3 = address.postcode || ''
	// City town village
	const p4 = address.city || address.town || address.village || ''
	// State
	const p5 = address.state || address.municipality || ''
	// Return formation
	return `${p1} ${p2}, ${p3} ${p4}, ${p5}`
}

// Process event after query and before caching
export function preProcessEventStatic(event) {
	// Location display
	if(event?.location?.address)
		event.location.display = formatAddress(event.location.address)
	else
		event.location.display = `${event.location.coordinates[0].toFixed(8)}, ${event.location.coordinates[1].toFixed(8)}`
	// Return event
	return event
}

// Process event at render time
export function preProcessEventDynamic(event) {
	// Event start delta
	event.delta = Date.parse(event.start) - Date.now()
	// Event has not yet started
	event.upcoming = Date.parse(event.start) > Date.now()
	// Event is going on right now!
	event.ongoing = Date.parse(event.start) < Date.now() && Date.parse(event.end) > Date.now()
	// Has the event turned into a memory
	event.memory = Date.parse(event.end) < Date.now()
	// Check if event is locked
	event.locked = Date.parse(event.end) + event.locktime < Date.now()
	// Event will start somewhere this week
	event.thisWeek = Date.parse(event.end) > Date.now() && Date.now() > Date.parse(event.start) - 7*24*60*60*1000
	// Event is starting today (16h)
	event.today = Date.parse(event.end) > Date.now() && Date.now() > Date.parse(event.start) - 16*60*60*1000
	// Event is about to start soon (in 2h)
	event.soon = Date.parse(event.end) > Date.now() && Date.now() > Date.parse(event.start) - 6*60*60*1000
	// Event is almost about to start (in 30m)
	event.almost = Date.parse(event.end) > Date.now() && Date.now() > Date.parse(event.start) - 2*60*60*1000
	// Return processed event
	return event
}

// Process event before sending to backend
export function postProcessEvent(event) {
	// Map hosts to id
	// event.hosts = event.hosts.map(host => host.user && ({user: host?.user?._id}) || host)
	event.hosts = event.hosts.map(host => ({ext:host.ext, user: host?.user?._id}))
	console.log('post processed event', event)
	// Return processed event
	return event
}

// Relevants sort events
export function sort(events) {
	return events.sort( (a,b) => {
		// Convert dates
		const tn = Date.now()
		const ta = Date.parse(a.end)
		const tb = Date.parse(b.end)
		// Sort by upcoming
		if(ta > tn && tb > tn) 
			return ta-tb
		// Sort by latest memory
		return tb-ta
	})
}

