import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native'
import { ListItem, Input, Icon } from 'react-native-elements'
import constants from 'config/constants'

// Modal
import Modal from 'component/Modal'

// Hooks for authentication
import {useLogout, useLoggedin, useGetApi, useSetApi, useClearApi, useWebUrl, useSetWebUrl} from 'hook/authentication'

// View
function Settings({navigation}) {

	// Api url
	const {data:apiurl} = useGetApi()

	// Change api url
	const {mutate:setApiUrl} = useSetApi()

	// Clear api url
	const {mutate:clearApiUrl} = useClearApi()

	// Web url
	const {data:webUrl} = useWebUrl()

	// Set web url
	const {mutate:setWebUrl} = useSetWebUrl()

	// User loggedin
	const {data:loggedin} = useLoggedin()

	// Logout user
	const {mutate:logout} = useLogout()

	// Show modal
	const [showApi,setShowApi] = useState(false)

	// Show modal
	const [showWeb,setShowWeb] = useState(false)

	// Reder component
	return (
	<View>
		<ListItem key='api' bottomDivider onPress={()=>setShowApi(true)}>
			<ListItem.Content>
				<ListItem.Title> API </ListItem.Title>
        <ListItem.Subtitle> {apiurl || constants.apiurl} </ListItem.Subtitle>
			</ListItem.Content>
		</ListItem>
		<ListItem key='web' bottomDivider onPress={()=>setShowWeb(true)}>
			<ListItem.Content>
				<ListItem.Title> Website </ListItem.Title>
        <ListItem.Subtitle> {webUrl} </ListItem.Subtitle>
			</ListItem.Content>
		</ListItem>
			<ListItem key='logout' bottomDivider onPress={()=>{
				if(loggedin)
					logout()
				else
					navigation?.navigate('login')
				}}>
			<ListItem.Content>
				<ListItem.Title> {(loggedin?'Logout':'Login')} </ListItem.Title>
        <ListItem.Subtitle> {loggedin?'You are logged in':'You are logged out'} </ListItem.Subtitle>
			</ListItem.Content>
		</ListItem>

		{/* API POPUP */}
			<Modal 
				visible={showApi} 
				onRequestClose={()=>setShowApi(false)} 
			>
			<Input
				label='API URL'
				value={apiurl || ''}
				placeholder={constants.apiurl}
				onChangeText={text => setApiUrl(text)}
				rightIcon={<Icon 
						name='close'
						size={20}
						onPress={() => clearApiUrl()}
					/>}
				/>
		</Modal>

		{/* WEB POPUP*/}
			<Modal 
				visible={showWeb} 
				onRequestClose={()=>setShowWeb(false)} 
			>
			<ListItem onPress={()=>{setWebUrl('https://stable.join.vosenterprises.eu'); setShowWeb(false)}}>
				<ListItem.Content>
					<ListItem.Title> Stable </ListItem.Title>
					<ListItem.Subtitle> https://stable.join.vosenterprises.eu</ListItem.Subtitle>
				</ListItem.Content>
			</ListItem>
			<ListItem onPress={()=>{setWebUrl('https://unstable.join.vosenterprises.eu'); setShowWeb(false)}}>
				<ListItem.Content>
					<ListItem.Title> Unstable </ListItem.Title>
					<ListItem.Subtitle> https://unstable.join.vosenterprises.eu</ListItem.Subtitle>
				</ListItem.Content>
			</ListItem>
			<ListItem onPress={()=>{setWebUrl(apiurl); setShowWeb(false)}}>
				<ListItem.Content>
					<ListItem.Title> Local </ListItem.Title>
					<ListItem.Subtitle> {apiurl} </ListItem.Subtitle>
				</ListItem.Content>
			</ListItem>
		</Modal>
	</View>

	)
}

// Export component
export default Settings

// Styles
const styles = StyleSheet.create({})
