import React, {useState,useEffect} from 'react'
import {StyleSheet, Linking, View} from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Icons
import { Feather } from '@expo/vector-icons'

// Component
export default function SourceButton({onPress,...props}) {

	// Loading
	const [loading,setLoading] = useState()

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState()

	// Track disabled
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={!props.source || disabled}
				onPress={ async () => {
					await Linking.openURL(props.source)
				}}
				type='clear'
				icon={<Icon 
					name='external-link' size={24} 
					color={(disabled || !props.source) && 'darkgrey'}
					type='feather'
				/>}
			/>
			<Paragraph>-</Paragraph>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignItems:'center',
	},
	circleContainer: {
		backgroundColor:'black',
		borderRadius:50,	
		padding:10,
		borderWidth:0.65,
		borderColor:'white',
	},
	textBottom:{
		fontSize:14,
		fontWeight:'800',
	},
});
