import React,{useState,useEffect} from 'react'
import { StyleSheet, View } from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// Elements
import { Icon } from 'react-native-elements'

// Time library
import moment from 'moment'

// Use location
import { useLocation } from 'hook/location'

// Distance calculator
import { getDistance } from 'geolib'

// Event preview
export default function InfoBarBottom(props) {

	// User
	const [user, setUser] = useState(props.user)

	// Track user
	useEffect(() => setUser(props.user),[props.user])

	// Event
	const [event, setEvent] = useState(props.event)

	// Track event
	useEffect(() => setEvent(props.event),[props.event])

	// Get location
	const {data:device} = useLocation()

	// Distance between user and event
	const [distance, setDistance] = useState(undefined)

	// Track distance
	useEffect(() => {
		setDistance( device && event && getDistance(
			{longitude:device?.coords?.longitude,latitude:device?.coords?.latitude},
			{longitude:event?.location?.coordinates[0],latitude:event?.location?.coordinates[1]}
		)/1000)
	},[device,event])

	// Render component
	return (
		<View style={[styles.container, props.style]}>
			{event.ongoing &&
				<Icon name='controller-record' size={18} color='red' type='entypo'/>}
			{event.memory && !event.locked &&
				<Icon name='camera' size={18} type='feather'/>}
			{event.locked   && 
				<Icon name='lock' size={18} type='feather'/>}
			{event.upcoming && 
				<Icon name='calendar' size={18} type='material-community'/>}
			<Paragraph> { moment(event?.start).fromNow() }  </Paragraph>
			<Icon name="timelapse" size={18} type='material'/>
			<Paragraph> {moment.duration(moment(event?.start) - moment(event?.end)).humanize()}  </Paragraph>
			{distance && <Icon name="location-pin" size={18} type='material'/>}
			{distance && <Paragraph> {distance?.toFixed(3)}km  </Paragraph>}
			{event?.ticket && <Icon name='pricetag' size={18} type='ionicon'/> }
			{event?.ticket && <Paragraph> {event?.ticket?.price/100}€ </Paragraph>}
		</View>
	)
}

const styles = StyleSheet.create({
	container:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		textAlign:'center',
		color:'white',
	},
})
