import React, {useState,useEffect} from 'react'
import { Platform, StyleSheet, View } from 'react-native'

// Modal
import Modal from 'component/Modal'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, SearchBar, Icon, ListItem } from 'react-native-elements'

// React native maps
import MapView, { Marker } from 'react-native-maps'

// Hook to get device location
import { useLocation, useGeocodeForward } from 'hook/location'

// Debounce hook
import { useDebounce } from 'hook/utilities'

// Dark map style
import darkmap from 'resource/dark.map'

// Component
export default function LocationPicker(props) {

	// Device location
	const {data:device} = useLocation({})

	// Location information
	const [location, setLocation] = useState(props.location)

	// Track location information
	useEffect(() => setLocation(props.location), [props.location])

	// Latitude state
	const [latitude, setLatitude] = useState(props?.location?.coordinates[1] || null)

	// Track latitude
	useEffect(() => setLatitude(props?.location?.coordinates[1] || null), [props.location])

	// Longitude state
	const [longitude, setLongitude] = useState(props?.location?.coordinates[0] || null)

	// Track longitude
	useEffect(() => setLongitude(props?.location?.coordinates[0] || null), [props.location])

	// Modal
	const [showModal,setShowModal] = useState(false)

	// Search query
	const [query, setQuery] = useState('')

	// Debounced query
	const _query = useDebounce(query, 1000)
	
	// Track query
	useEffect(() => geocode(_query),[_query])

	// Opened search
	const [open, setOpen] = useState(false)

	// Geo coding
	const { data:list = [], mutate:geocode } = useGeocodeForward()
	
	console.log('latitude',latitude)
	console.log('longitude',longitude)

	// Render component
	return (
		<View style={styles.container}>
			<View style={styles.buttonContainer}>
				<Button
					{...props}
					type='clear'
					icon={<View style={styles.buttonContainer}>
						<Icon name="location-outline" type='ionicon' size={18} color={location?.coordinates?'white':'grey'}/>
						<Paragraph>{location?.coordinates[1]?.toFixed(4)}</Paragraph>
						<Paragraph>{location?.coordinates?', ':'-'}</Paragraph>
						<Paragraph>{location?.coordinates[0]?.toFixed(4)}</Paragraph>
					</View>}
					onPress={() => setShowModal(true)}
				/>
			</View>
			<Modal
				visible={showModal}
				onRequestClose={() => setShowModal(false)}
				> 
				<SearchBar 
					containerStyle={styles.search}
					placeholder={latitude && longitude && `${latitude?.toFixed(8) }, ${longitude?.toFixed(8)}`}
					round={true}
					value={query} 
					onChangeText={text => setQuery(text)}
					onFocus={()=>setOpen(true)}
					onClear={()=>setOpen(false)}
				/>
				{ //!open && 
					<MapView 
						style={styles.map} 
						customMapStyle={darkmap}
						onPress={ event => {
							// Set query
							setQuery('')
							// Set new location
							props.onChange({
								coordinates: [
									event.nativeEvent.coordinate.longitude, 
									event.nativeEvent.coordinate.latitude
								],
								address:undefined
							})
						}}
						initialRegion={{
							latitude:  latitude  || device?.coords?.latitude  || 50,
							longitude: longitude || device?.coords?.longitude || 11,
							latitudeDelta: 0.015,
							longitudeDelta: 0.015,
						}}
						>
						{ 
							// latitude && longitude && 
							<Marker title='title' description='description' coordinate={{latitude:10,longitude:10}}/> 
						}
					</MapView>
				}
				{ open && list.map((e,i) => (
					<ListItem 
						key={i} 
						bottomDivider
						onPress={() => {
							// Set query
							setQuery(list[i].display_name)
							// Set new location
							props.onChange({
								coordinates:[Number(list[i].lon),Number(list[i].lat)],
								address: list[i].address
							})
							setOpen(false)
						}}
					>
						<ListItem.Content>
							<ListItem.Title>{e.display_name}</ListItem.Title>
							<ListItem.Subtitle>{`${e.lat},${e.lon}`}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
				))

				}
			</Modal>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	search:{
		backgroundColor:'black'
	},
	buttonContainer:{
		flexDirection:'row',
		alignContent: 'center',
		alignItems:'center',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	},
	map: {
		height: '100%',
		width: '100%'
	},
	overlay: {
		top:0,
		margin:0,
		padding:0,
		height:'80%',
		width: '90%'
	},
	backdrop: {
		top:0,
		margin:0,
		padding:0
	}
})




	/*
		<Modal
			visible={showModal}
			onRequestClose={() => {
				// Hide modal
				setShowModal(false)
				// Trigger change event
				if(latitude && longitude)
				onChange({latitude,longitude})
			}}
			> 
			<SearchBar 
				containerStyle={styles.search}
				placeholder="search" 
				round={true}
				value={search} 
				onChangeText={text=>setSearch(text)}
			/>
			<MapView 
				style={styles.map} 
				customMapStyle={darkmap}
				onPress={ event => {
					setLatitude(event.nativeEvent.coordinate.latitude)
					setLongitude(event.nativeEvent.coordinate.longitude)
				}}
				initialRegion={{
					latitude:  latitude  || device?.coords?.latitude  || 50,
					longitude: longitude || device?.coords?.longitude || 11,
					latitudeDelta: 0.015,
					longitudeDelta: 0.015,
				}}
				>
				{ latitude && longitude && 
					<Marker coordinate={{latitude,longitude}} > 
						<Icon name="location-pin" size={32} color="white" type='material'/>
					</Marker> 
				}
			</MapView>
		</Modal>
*/

/*
*
						<Paragraph>{latitude?.toFixed(4)}</Paragraph>
						<Paragraph>{(latitude && longitude)?', ':'-'}</Paragraph>
						<Paragraph>{longitude?.toFixed(4)}</Paragraph>
* */
