// React
import React from 'react'

// React native
import { TouchableWithoutFeedback, Keyboard, LogBox, StatusBar, Linking} from 'react-native'

// Keep awake
import { useKeepAwake } from 'expo-keep-awake'

// Import Routes to use
import Routes from 'source/Routes'

import Constants from 'expo-constants'

// Time library
import moment from 'moment'

// React query provider
import { QueryClientProvider, QueryClient} from 'react-query'

// Containers
import {Wrapper, Row, Center, SpreadRow, MaxWidth } from 'style/containers'

// Toast messages
import Toast from 'react-native-toast-message'

// React query options
import rqOptions from 'config/query'

// Create the react query client
const queryClient = new QueryClient({ defaultOptions: rqOptions })

// Set short relative time
moment.locale('*', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s:  '1s',
    ss: '%ss',
    m:  '1m',
    mm: '%dm',
    h:  '1h',
    hh: '%dh',
    d:  '1d',
    dd: '%dd',
    M:  '1M',
    MM: '%dM',
    y:  '1Y',
    yy: '%dY'
  }
})

// Test component
import Auth from 'isolates/Auth.js'
import Feed from 'isolates/Feed.js'
import Create from 'isolates/Create.js'

// Disable some warnings
LogBox.ignoreLogs(['Setting a timer']);

// Log env
console.log('environment variables:', process.env)

// 
console.log('manifest.extra',Constants.manifest.extra)

// Starting point
export default function App() {

	// Keep screen awake in development mode
	if(process.env.NODE_ENV === 'development')
		useKeepAwake()

	// Render function
	return (
		<QueryClientProvider client={queryClient}>
			<Create/>
			<StatusBar barStyle="light-content"/>
			<Toast/>
		</QueryClientProvider>
	)
}

		// <TouchableWithoutFeedback onPress={()=>Keyboard.dismiss()}>
		// </TouchableWithoutFeedback>
