import React, {useState,useEffect} from 'react'
import { StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native'

// Modal
import Modal from 'component/Modal'

// Typography
import { Paragraph} from 'style/typography'

// React native elements
import { Button, Input, Icon, Avatar, ListItem } from 'react-native-elements'

// Add post hook
import { usePost, useLikePost, useDislikePost, useClearLikePost, useReply } from 'hook/events'

// User
import { useUser } from 'hook/user'

// Time library
import moment from 'moment'

// Create a new post
function Add(props) {

	// New post text content
	const [text, setText] = useState('')

	// Render component
	return (
		<View>
			<Input 
				multiline={true}
				numberOfLines={1}
				value={text}
				onChangeText={value => setText(value)}
				placeholder='write your comment here...' 
				maxLength={256}
				rightIcon={<View style={{
					flex:1, 
					flexDirection:'row',
					alignContent: 'center',
					alignItems: 'center'
					}}>
					{props.image && <Button
						type='clear'
						icon={{
							name:'image',
							type:'material-community',
							size:18,
						}}
					/>}
					{props.video && <Button
						type='clear'
						icon={{
							type:'material-community',
							name:'video',
							size:20,
						}}
					/>}
					<Button
						type='clear'
						icon={{
							name:'arrow-right-bold',
							type:'material-community',
							size:20
						}}
						loading={props.loading}
						disabled={!text || props.loading}
						color={!text && 'grey'}
						onPress={() => {
							props.onAdd(text)
							setText('')
						}}
					/>
				</View>}
			/>
		</View>
	)
}

// Post component
function Post({event, post, onReply}) {

	// Show reply input
	const [show, setShow] = useState(false)

	// User info
	const { data:user } = useUser()

	// Like a post
	const { mutate:like } = useLikePost()

	// Dislike a post
	const { mutate:dislike } = useDislikePost()

	// Clear likes and dislike on post
	const { mutate:clearLike } = useClearLikePost()
	
	// Render component
	return (
		<View>
			<View style={{flex:1, flexDirection:'row', margin:10}}>
				<Avatar 
					source={post?.user?.avatar && {uri:post?.user?.avatar}}
					icon={!post?.user?.avatar && {name:'person', type:'material', size:26}}
					title={ !post?.user?.avatar && post?.user?.firstname[0].toUpperCase() }
					containerStyle={{ backgroundColor: '#6733b9' }}
					size={56}
					rounded
				/>
				<View style={{marginLeft:10}}>
					<Paragraph>{post.user.firstname} {post.user.lastname} {moment(post.date).fromNow()}</Paragraph>
					<Paragraph style={{marginLeft:5}}>{post.text}</Paragraph>
					<View style={{flex:1, flexDirection:'row', alignContent:'center'}}>
						<TouchableOpacity
							onPress={() => {
								if(post.likes.includes(user._id))
									clearLike({eid:event._id, pid:post._id})
								else
									like({eid:event._id, pid:post._id})
							}}
						>
							<Icon 
								type='antdesign' 
								name={post.likes.includes(user._id)?'like1':'like2'}
								size={15}
							/>
						</TouchableOpacity>
						<Paragraph> {post.likes.length} </Paragraph>
						<TouchableOpacity
							onPress={() => {
								if(post.dislikes.includes(user._id))
									clearLike({eid:event._id, pid:post._id})
								else
									dislike({eid:event._id, pid:post._id})
							}}
						>
							<Icon 
								type='antdesign' 
								name={post.dislikes.includes(user._id)?'dislike1':'dislike2'}
								size={15}
							/>
						</TouchableOpacity>
						<Paragraph> {post.dislikes.length} </Paragraph>
						<TouchableOpacity
							onPress={() => setShow(!show)}
							>
							<Paragraph> {show?'CLOSE':'REPLY'} </Paragraph>
						</TouchableOpacity>
					</View>
				</View>
			</View>
			<View style={{marginLeft:52}}>
				{ show && <Add
					onAdd={text => {onReply(post._id, text); setShow(false)}}
				/>}
				{post.replies.map(reply => <Reply key={reply._id} reply={reply}/>)}
			</View>
		</View>
	)
}

// Reply component
function Reply({reply}) {
	return (
		<View>
			<View style={{flex:1, flexDirection:'row', margin:10}}>
				<Avatar 
					source={reply?.user?.avatar && {uri:reply?.user?.avatar}}
					icon={!reply?.user?.avatar && {name:'person', type:'material', size:26}}
					title={ !reply?.user?.avatar && reply?.user?.firstname[0].toUpperCase() }
					containerStyle={{ backgroundColor: '#138399' }}
					size={42}
					rounded
				/>
				<View style={{marginLeft:10}}>
				<Paragraph>{reply.user.firstname} {reply.user.lastname} {moment(reply.date).fromNow()}</Paragraph>
				<Paragraph style={{marginLeft:5}}>{reply.text}</Paragraph>
				</View>
			</View>
		</View>
	)
}

// Component
export default function PostsButton({onPress,...props}) {

	// Number of people who like event
	const [level,setLevel] = useState()

	// Track number of people who like event
	useEffect(()=>setLevel(props.level),[props.level])

	// Loading
	const [loading,setLoading] = useState()

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState()

	// Track loading
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Overlay shown or not
	const [show, setShow] = useState(false)

	// Add a new post mutation hook
	const { mutate:addPost, isLoading:isPosting } = usePost()

	// Add a new reply mutation hook
	const { mutate:addReply, isLoading:isReplying } = useReply()

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={disabled}
				onPress={() => setShow(true)}
				type='clear'
				icon={<Icon name='comment-multiple-outline' size={24} color={disabled && 'grey'} type='material-community'/> }
			/>
			<Paragraph>{ props.event.posts.length }</Paragraph>
			<Modal
				visible={show} 
				animationType='slide'
				onRequestClose={ () => setShow(false) }
			>
				<Add
					onAdd={ text => addPost({id:props.event._id, text}) }
					loading={ isPosting }
				/>
				<ScrollView>
					{props.event.posts.map(post => 
						<Post 
							key={post._id} 
							event={props.event}
							post={post}
							onReply={(pid,text) => addReply({eid:props.event._id, pid, text})}
						/>
					)}
				</ScrollView>
			</Modal>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignItems:'center',
	},
	circleContainer: {
		backgroundColor:'black',
		borderRadius:50,	
		padding:10,
		borderWidth:0.65,
		borderColor:'white',
	},
	textBottom:{
		fontSize:14,
		fontWeight:'800',
	},
});
