import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native'
import { Input, Button } from 'react-native-elements';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

// Modal
import Modal from 'component/Modal'

// Bar code scanner
import { BarCodeScanner } from 'expo-barcode-scanner';

// Icons
import { Icon } from 'react-native-elements'

// code verifier
function CodeVerifier({onVerify, placeholder}) {
	// Code
	const [code, setCode] = useState('')
	// Registration verified
	const [verified, setVerified] = useState(false)
	// Modal
	const [show, setShow] = useState(false)
	// promise tracker
	const {promiseInProgress} = usePromiseTracker({delay:0})

	// Verify code
	async function verifyCode() {
		if(code.length == 8) setVerified(Boolean(await trackPromise(onVerify(code))))
	}

	// Verify code listener
	useEffect( () => {verifyCode()}, [code])

	// Render code verifier component
	return (
			<View>
				<Button type='clear'
					disabled={verified}
					loading={promiseInProgress}
					loadingProps={{size:150}}
					icon={<Icon name="qrcode-scan" type='material-community' size={150} color={verified?'green':'gray'}/>}
					onPress={async () => {
						await BarCodeScanner.requestPermissionsAsync();
						setShow(true)
					}}
				/>
				<Input
					disabled={verified}
					placeholder={placeholder || 'code'}
					leftIcon={<Icon name={verified?'check':'cross'} type='entypo' size={24} color={verified?'green':'red'} onPress={() => setCode("")}/>}
					value={code}
					onChangeText={async text => {
						setCode(text)
					}}
				/>
			<Modal 
				visible={show} 
				onRequestClose={() => setShow(false)} 
				>
					<BarCodeScanner style={{flex:1}}
						barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
						onBarCodeScanned={ ({data}) => {
							setCode(data)
							setShow(false)
						}}
					/>
				</Modal>
			</View>
	)
}

// Export view
export default CodeVerifier

// Styles
const styles = StyleSheet.create({
	ScrollView: {
		flex:1,
	},
	page: {
		flex:1,
		width:'100%',
		height:'100%',
	},
	scanner: {
	},
	container: {
		flex: 1,
		backgroundColor:'white',
		paddingVertical:10,
	},
	rowContainer:{
		flexDirection:'row',
		justifyContent: 'center',
	},
	spaceContainer:{
		width:'100%',
		flexDirection:'row',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	centerContainer:{
		width:'100%',
		flexDirection:'row',
		justifyContent: 'center',
		paddingHorizontal:10,
	},
})
