import React, {useState,useEffect} from 'react'
import {StyleSheet, Linking, View} from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

import { Button, Icon } from 'react-native-elements'

// Component
export default function TicketButton(props) {

	// Ticket url state
	const [url,setUrl] = useState()

	// Ticket url tracker
	useEffect(()=>setUrl(props.url),[props.url])

	// Ticket price state
	const [price,setPrice] = useState()

	// Ticket price tracker
	useEffect(()=>setPrice(props.price),[props.price])

	// Loading
	const [loading,setLoading] = useState()

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState()

	// Track disabled
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={disabled}
				onPress={async ()=> {
					await Linking.openURL(url)
				}}
				type='clear'
				icon={<Icon name='pricetag' size={24} color={disabled && 'grey'} type='ionicon'/> }
			/>
			<Paragraph>{ price?`${(price/100).toFixed(2)} €`:'-' }</Paragraph>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignContent: 'center',
		alignItems:'center',
	}
})
