// Import core
import api from './core'

import { Buffer } from 'buffer'

import { base64ToBlob } from 'helper/util'

// Fetch user
export async function fetchUser(id) {
	if(id)
		return (await api.get(`/user/${id}`)).data
	else
		return (await api.get(`/user`)).data
}

// Get basic information on users
export async function fetchUsers(ids) {
	return (await api.post(`/users`, ids)).data
}

// Search for a user
export async function fetchUserSearch(query) {
	return (await api.post(`/users/search`, {query})).data
}

// Fetch user created events
export async function fetchUserCreatedEvents() {
	return (await api.get(`/user/events`)).data
}

// Fetch events user is going to
export async function fetchUserGoingEvents() {
	return (await api.get(`/user/going`)).data
}

// Fetch user saved events
export async function fetchUserSavedEvents() {
	return (await api.get(`/user/saves`)).data
}

// Fetch user liked events
export async function fetchUserLikedEvents() {
	return (await api.get(`/user/likes`)).data
}

// Patch avatar image Blob/File
export async function patchAvatar(image) {
	// Create form
	const form = new FormData()
	// Append file
	form.append('avatar', image)
	// Send form
	return (await api.patch(`/user/avatar`, form, {headers:{"content-type":"multipart/form-data"}})).data
}

