import React, {useState, useEffect} from 'react';
import { StyleSheet, View, ScrollView } from 'react-native'
import { Input, Button} from 'react-native-elements';

import HostPicker from 'component/HostPicker'
import CapacityPicker from 'component/CapacityPicker'
import DatePicker from 'component/DatePicker'
import TimePicker from 'component/TimePicker'
import DurationPicker from 'component/DurationPicker'
import LocationPicker from 'component/LocationPicker'
import TicketPicker   from 'component/TicketPicker'
import PrivateSwitch  from 'component/PrivateSwitch'
import FlyerPicker from 'component/FlyerPicker'

// View
export default function EventCreate({navigation,route}) {
	// Track event state
	const [event,setEvent] = useState({private:false})
	// Track prop change event
	useEffect(() => setEvent(props.event || {}),[props.event])
	// JSX
	return (
		<ScrollView style={styles.container}>

			<Input label='Title' placeholder='Event title'
				labelStyle={styles.input}
				value={event.title}
				onChange={e => setEvent({...event, title:e.nativeEvent.text})} />

			<Input label='Subtitle' placeholder='Event subtitle'
				labelStyle={styles.input}
				value={event.subtitle}
				onChange={e => setEvent({...event, subtitle:e.nativeEvent.text})} />

			<View style={styles.rowContainer}>
				<FlyerPicker size={185} onChange={url => setEvent({...event, flyer:url})}/>
				<View>
					<HostPicker host={events.host}
						onChange={host => setEvent({...event, host})}/>
					<CapacityPicker capacity={event.capacity}
						onChange={capacity => setEvent({...event, capacity})}
					/>
					<LocationPicker location={event?.location?.coordinates}
						onChange={location => console.log(location)}/>

					<DatePicker date={event.eventDate} minimumDate={new Date()}
						onChange={eventDate => ({...event, eventDate})}/>
					<TimePicker date={event.eventDate} minimumDate={new Date()}
						onChange={eventDate => setEvent({...event, eventDate})}/>

					<DurationPicker duration={event.duration}
						onChange={duration => setEvent({...event, duration:duration})}/>
					<TicketPicker ticket={event.ticket}
						onChange={ticket => setEvent({...event, ticket:ticket})}/>
					<PrivateSwitch private={event.private}
						onChange={selection => setEvent({...event, private:selection})}/>
				</View>
			</View>

			<Input label='Description' placeholder='Event description'
				value={event.description}
				multiline={true}
				onChange={e => setEvent({...event, description:e.nativeEvent.text})}/>

			<Button title='Create'
				onPress={e => props.onCreate(event)}
			/>

		</ScrollView>
	)
}

// Styles
const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor:'white',
		padding:10,
	},
	rowContainer:{
		flexDirection:'row',
		justifyContent: 'flex-start',
		// marginLeft:10,
	},
	spaceContainer:{
		width:'100%',
		flexDirection:'row',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	centerContainer:{
		width:'100%',
		flexDirection:'row',
		justifyContent: 'center',
		paddingHorizontal:10,
	},
	input:{
		padding:0,
		margin:0,
	}
})
