// React
import React, { useState, useEffect, useRef } from 'react'

// React native
import { StyleSheet, ScrollView, View, TouchableOpacity } from 'react-native'

// React helmet
import { Helmet } from 'react-helmet'

// Image component
import { Button, Icon } from 'react-native-elements'

// Views
import EventFrontBack from 'view/EventFrontBackView'

// Events hook
import { useEvent } from 'hook/events'

// Authentication hooks
import { useLoggedin } from 'hook/authentication'

// User hook
import { useUser } from 'hook/user'

// Derive screen size
import { useRenderArea } from 'hook/utilities'

// Event component
export default function Event({navigation, route, onScrollLock, ...props}) {

	// ScrollView ref
	const _ScrollView = useRef()

	// Event id
	const [id, setId] = useState(route?.params?.id || props?.id)

	// Track event id
	useEffect(() => setId(route?.params?.id || props?.id), [route?.params?.id, props?.id])

	// Loggedin
	const { data:loggedin } = useLoggedin()

	// User query
	const { data:user, isLoading:userLoading } = useUser()

	// Event query
	const { data:event, isFetched:eventFetched } = useEvent(id, {
		placeholderData: props.event,
		staleTime: 5*60*1000,
	})

	// Event upcoming
	const [upcoming, setUpcoming] = useState(true)

	// Track upcoming
	useEffect(() => setUpcoming(Date.now() < Date.parse(event?.start)),[event])

	// Set navigation options
	useEffect(() => {
		navigation?.setOptions({
			title: event?.title,
			headerRight: () => {
				if(user?.events.includes(id))
					return (upcoming && !event?.cancelled && <Button type='clear' 
						icon={<Icon name="edit" size={18} type='entypo'/>}
						onPress={ () => navigation.replace('create',{id})}
					/>)
			}
		})
	},[event,id,upcoming])

	// get the available render area
	var {width, height} = useRenderArea()

	// Render component
	return  <>
		<Helmet>
			<meta property='og:title' content={event?.title} />
			<meta property='og:description' content={event?.description} />
			<meta property='og:image' contnent={event?.flyer}/>
			<meta property='og:url' content={window?.location?.host + window?.location?.pathname+window?.location?.search}
			/>
			{/*
				<meta property='og:site_name' content='my website'/>
				<meta name='twitter:card' content='summary twitter card' />
				<meta name='twitter:image:alt' content='Alt text for image' />
				<meta property='fb:app_id' content='your app id'/>
				<meta name='twitter:site' content='@website-username'/>
			*/}
		</Helmet>
		{ eventFetched && 
			<EventFrontBack
				navigation={navigation}
				width={width}
				height={height}
				user={user}
				event={event}
				loggedin={loggedin}
			/> 
		}
	</>
}
