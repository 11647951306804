// React (components)
import React, {useState,useEffect,useLayoutEffect,useRef} from 'react';
import { Platform, StyleSheet, View, FlatList, ScrollView} from 'react-native'
import { SearchBar, Button, Icon } from 'react-native-elements';

// React native maps
import MapView, {Marker} from 'react-native-maps'

// Dark map style
import darkmap from 'resource/dark.map'

// Components
import EventCard from 'component/EventCard'

// Map view
export default function EventMap({_map, navigation, ...props}) {

	// List ref
	const _list = useRef()

	// User
	const [user, setUser] = useState(props.user)

	// Track user
	useLayoutEffect(() => setUser(props.user), [props.user])

	// Events
	const [events, setEvents] = useState(props.events)

	// Track events
	useLayoutEffect(() => setEvents(props.events), [props.events])

	// Device
	const [device, setDevice] = useState(props.device)

	// Region
	const [region, setRegion] = useState(getRegion(device))

	// Generate region
	function getRegion(device) {
		return {
			longitude: device?.coords?.longitude || 0,
			latitude:  device?.coords?.latitude  || 0, 
			longitudeDelta: 0.1,
			latitudeDelta: 0.1,
		}
	}

	// Track device and region
	useLayoutEffect(() => {
		setDevice(props.device)
		setRegion(getRegion(props.device))
	}, [props.device])

	// Card width in pixels
	const CARD_WIDTH = 194

	// Max score
	const [max,setMax] = useState(4.5)

	// Min score
	const [min,setMin] = useState(4.1)

	// Compute score bounds
	useEffect(() => {
		const scores = events?.map(event => event._score)
		if(scores) {
			setMax(Math.max(...scores))
			setMin(Math.min(...scores))
		}
	},[events])

	// Render screen
	return (
		<View style={[styles.container]}>
			<MapView 
				ref={_map}
				style={{flex:1}}
				customMapStyle={darkmap}
				initialRegion={region}
				loadingBackgroundColor='black'
				>
				{device && <Marker coordinate={device?.coords} > 
					<Icon name="person-pin" size={32} type='material'/>
				</Marker>}
				{events && events.map((event,index) => <Marker 
					key={index}
					coordinate={{longitude:event?.location?.coordinates[0],latitude:event?.location?.coordinates[1]}} 
					title={event.title}
					onPress={e => {
						// Get event index
						const index = Number(e._targetInst.return.key)
						// Scroll to event index
						_list.current.scrollTo({x:index*CARD_WIDTH})
					}}
					tracksViewChanges={false}
					> 
					<Icon 
						name="pin" 
						type='ionicon'
						size={ 15*(event._score-min)/(max-min)+15} 
						color={event.ongoing && 'red' || event.almost && 'yellow' || event.soon && 'white' ||  event.today && 'lightgray' || 'gray'} 
					/>
				</Marker>)}
			</MapView>
			<View style={styles.cardContainer}>
				<ScrollView
					ref={_list}
					horizontal
					removeClippedSubviews
					maxToRenderPerBatch={4}
					scrollEventThrottle={1}
					pagingEnabled
					snapToInterval={CARD_WIDTH}
					snapToAlignment='center'
					contentInset={{
						top: 0,
						left: CARD_WIDTH/2,
						bottom:0,
						right:CARD_WIDTH/2
					}}
					contentContainerStyle={{
						paddingHorizontal: Platform.OS === 'android' ? CARD_WIDTH/2:0
					}}
				>
					{events?.map(event => <EventCard 
						key={event._id}
						styles={{margin:0}} 
						event={event} 
						user={user} 
						onPress={event => navigation.navigate('event',{id:event?._id})} 
					/>)}
				</ScrollView>
			</View>
		</View>
	)
}


// Styles
const styles = StyleSheet.create({
	container: {
		flex:1
	},
	cardContainer: {
		position:'absolute',
		width:'100%',
		height:300,
		bottom:50,
		borderTopWidth: 0,
		borderBottomWidth: 0,
		borderBottomColor: 'grey',
		borderTopColor: 'grey',
	},
})



