import React,{useState, useEffect} from 'react'
import { StyleSheet, View} from 'react-native'

// Typography
import {Paragraph} from 'style/typography'

// React native elements
import { Card, Icon } from 'react-native-elements'

// Stamp component
import Stamp from 'component/Stamp'

// Time library
import moment from 'moment'

// Use location
import {useLocation} from 'hook/location'

// Distance calculator
import {getDistance} from 'geolib'

// Event preview
export default function EventCard(props) {

	// User
	const [user, setUser] = useState(props.user)

	// Track user
	useEffect(() => setUser(props.user),[props.user])

	// Event
	const [event, setEvent] = useState(props.event)

	// Track event
	useEffect(() => setEvent(props.event),[props.event])

	// User saved event
	const saved = user?.saves.includes(event?._id)

	// User is going to event
	const going = user?.going.includes(event?._id)

	// User liked event
	const liked = user?.likes.includes(event?._id)

	// Get location
	const {data:device} = useLocation()

	// Distance between user and event
	const [distance, setDistance] = useState(undefined)

	// Track distance
	useEffect(() => {
		setDistance( device && getDistance(
			{longitude:device?.coords.longitude,latitude:device?.coords.latitude},
			{longitude:event?.location.coordinates[0],latitude:event?.location.coordinates[1]}
		)/1000)
	},[device,event])

	// Render component
	return (
		<View style={[styles.container]}>
			<Card 
				containerStyle={[ styles.cardContainer, event.ongoing && {borderColor:'red'} ]}
			>
				<View style={styles.row}>
					{event?.upcoming && 
						<Icon name='star' size={12} color={saved && 'yellow'} type='feather'/>}
					{event?.upcoming && 
						<Paragraph> {event?.saves} </Paragraph>}
					{event?.upcoming && !event.source && 
						<Icon name='user' size={12} color={going && 'lightgreen'} type='feather'/>}
					{event?.upcoming && !event.source && 
						<Paragraph> {event.going.length}/{event.capacity}  </Paragraph>}
					{event.memory && <Icon name='heart' size={12} color={liked && '#eb82fd'} type='feather'/>}
					{event.memory && <Paragraph> {event?.likes}  </Paragraph>}
					{!event?.upcoming && 
						<Icon name='comment-multiple-outline' size={12} type='material-community'/>}
					{!event?.upcoming && 
						<Paragraph> {event?.posts?.length}  </Paragraph>}
					<Icon name={event.exclusive?'eye-off':'eye'} size={12} color='white' type='feather'/>
					<Paragraph> </Paragraph>
					{user?.events.includes(event?._id) && 
						<Icon name='flag' size={12} type='feather'/>}
					<Paragraph> </Paragraph>
					{event?.ticket && <Icon name='ios-pricetag-outline' size={12} type='ionicon'/> }
					<Paragraph> </Paragraph>
					{ event.source && <Icon name='external-link' size={12} type='feather'/>}
				</View>
				<Card.Image 
					containerStyle={[styles.cardImageContainer,{opacity:event.memory?0.25:1.0}]} 
					source={{uri:event?.flyer}} style={{width:150, height:200, resizeMode:'contain'}}
					onPress={() => props.onPress(event)}
				/>
				<View style={styles.row}>
					{event.ongoing  && <Icon size={12} name='controller-record' color='red' type='entypo'/>}
					{event.memory && !event.locked && <Icon size={12} name='camera' color='white' type='feather'/>}
					{event.locked && <Icon name='lock' size={12} type='feather'/>}
					{event.upcoming && <Icon size={12} name='calendar' color='white' type='feather'/>}
					<Paragraph> {moment(event?.start).fromNow()}  </Paragraph>
					{distance && <Icon size={12} name='location-pin' color='white' type='entypo'/>}
					{distance && <Paragraph> {distance?.toFixed(0)}km </Paragraph>}
				</View>
				{ event?.cancelled &&
					<View 
						style={styles.stampwrapper}
						pointerEvents={'none'}
					>
						<Stamp
							style={[styles.stamp]}
						>CANCELLED</Stamp>
					</View>
				}
			</Card>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex:1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	row: {
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'center',
	},
	card:{
		flex:1,
		backgroundColor: 'black',
	},
	cardContainer:{
		margin:10,
		borderRadius:5,
		borderWidth: 2,
		borderColor: '#444444',
		paddingHorizontal:10,
		paddingVertical:5,
	},
	cardImageContainer: {
		backgroundColor: 'black',
	},
	cardWrapper: {
		backgroundColor: 'black',
	},
	image:{
		width:150,
		height:200,
		resizeMode: 'contain',
	},
	cancelled_container: {
		padding:5,
		backgroundColor:'black',
		position:'absolute',
		left:'0%',
		top:'45%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
		transform:[{rotate:'-45deg'}],
	},
	cancelled_text: {
		textAlign: 'center',
		fontWeight:'bold', 
		fontSize:22, 
		color:'red',
	},
	stampwrapper:{
		position:'absolute',
		justifyContent:'center',
		width:'100%',
		height:'100%',
	},
	stamp:{
		paddingVertical:18
	},
})
