import { createNavigationContainerRef } from '@react-navigation/native';

// Navigation reference
export const navigationRef = createNavigationContainerRef()

// Navigator
export function navigate(name, params) {
  if (navigationRef.isReady()) 
    navigationRef.navigate(name, params)
}
