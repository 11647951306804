import React, { useState, useEffect, useCallback } from 'react'
import { Platform, StyleSheet, Text, View, ScrollView, Alert } from 'react-native'
import { Input, Switch, Button} from 'react-native-elements'

// Alerts
import { cancelEventAlert } from 'alert/event'

// Containers
import { Row } from 'style/containers'

// Components
import HostPicker from 'component/HostPicker'
import CapacityPicker from 'component/CapacityPicker'
import DatePicker from 'component/DatePicker'
import TimePicker from 'component/TimePicker'
import DurationPicker from 'component/DurationPicker'
import LocationPicker from 'component/LocationPicker'
import TicketPicker   from 'component/TicketPicker'
import PrivateSwitch  from 'component/PrivateSwitch'
import FlyerPicker from 'component/FlyerPicker'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Icon } from 'react-native-elements'

// Import util
import { dataURLToBlob } from 'helper/util'

// Events hook
import { useEvent, useSetEvent, useCancel } from 'hook/events'

// Address formatting
import { formatAddress } from 'function/events'

// View
export default function SetEvent({navigation,route,...props}) {

	// Event query
	const { data:event } = useEvent(route?.params?.id || props?.id, {
		placeholderData: props?.event,
		staleTime: Infinity,
	})

	// Cancel event mutation
	const { mutateAsync:canelEvent } = useCancel()

	// Event mutation
	const { mutateAsync:setEvent } = useSetEvent()

	// Local event
	const [local, setLocal] = useState(event || props.event)

	// Track local event
	useEffect(() => setLocal( {...event, end:undefined, _id:undefined, owner:undefined} || {}), [event])

	// Local flyer
	const [flyer, setFlyer] = useState()

	// Hack
	const [scrollHeight, setScrollHeight] = useState(null)

	// Set navigation options
	useEffect(()=>{
		navigation?.setOptions({
			title: event?._id?'Change event':'Create event',
			headerRight: () => (<Row>
				{route?.params?.id && <Button type='clear' 
					icon={<Icon name="cancel" size={18} type='material-community' color='red'/>}
					onPress={ () => cancelEventAlert( () => {
						canelEvent(event._id)
						navigation.pop()
					})}
				/>}
				<Button type='clear' 
					icon={<Icon name="check" size={18} type='feather' color='lightgreen'/>}
					onPress={ async () => {
						// Construct File or Payload
						const payload = (Platform.select({
							web:  () => flyer && dataURLToBlob(flyer.uri),
							default: () => flyer && ({
								uri: flyer.uri,
								type: 'image/jpeg',
								name: 'flyer'
							})
						}))()
						// Set event backend
						const updated = await setEvent({event:{...local, flyer:undefined}, flyer:payload ,id:event?._id})
						// Navigate to created or updated event
						navigation.replace('event', {id:updated?._id})
					}}
				/>
			</Row>)
		})
	},[local,event,flyer])

	// Render component
	return (
		<ScrollView style={styles.container}>
			<Input label='Title' placeholder='Event title'
				labelStyle={styles.input}
				value={local?.title || ''}
				onChange={e => setLocal({...local, title:e.nativeEvent.text})} />
			<Input label='Subtitle' placeholder='Event subtitle'
				labelStyle={styles.input}
				value={local?.subtitle || ''}
				onChange={e => setLocal({...local, subtitle:e.nativeEvent.text})} />
			{local?.location?.address && <Paragraph>
				{formatAddress(local.location.address)}
			</Paragraph>}
			<View style={styles.rowContainer}>
				<FlyerPicker 
					width={185}
					height={285} 
					onChange={image => setFlyer(image)} 
					image={flyer?.uri || event?.flyer}
				/>
				<View>
					<HostPicker hosts={local?.hosts}
						onAdd={hosts => setLocal({...local, hosts})}/>
					<CapacityPicker capacity={local?.capacity}
						onChange={capacity => setLocal({...local, capacity})}
					/>
					<LocationPicker
						location={local?.location}
						onChange={location => setLocal({...local, location})}
					/>
					<DatePicker date={local?.start}
						onChange={date => setLocal({...local, start:date})}/>
					<TimePicker date={local?.start}
						disabled = {Platform.OS === 'web'}
						onChange={date => setLocal({...local, start:date})}/>
					<DurationPicker start={local?.start} duration={local?.duration}
						onChange={duration => setLocal({...local, duration:duration})}/>
					<TicketPicker ticket={local?.ticket}
						onChange={ticket => setLocal({...local, ticket:ticket})}/>
					<PrivateSwitch private={local?.exclusive}
						onChange={selection => setLocal({...local, exclusive:selection})}/>
				</View>
			</View>
			<Input label='Description' placeholder='Event description'
				value={local?.description || ''}
				textAlignVertical='top'
				numberOfLines={5}
				multiline={true}
				style={{ height: scrollHeight }}
				onChange={e => {
					setLocal({...local, description:e.nativeEvent.text})
					setScrollHeight(e.target.scrollHeight)
				}}
			/>
		</ScrollView>
	)
}

// Styles
const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding:10,
	},
	rowContainer:{
		flexDirection:'row',
		justifyContent: 'flex-start',
		marginBottom:12,
	},
	spaceContainer:{
		width:'100%',
		flexDirection:'row',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	centerContainer:{
		width:'100%',
		flexDirection:'row',
		justifyContent: 'center',
		paddingHorizontal:10,
	},
	input:{
		padding:0,
		margin:0,
	},
	row:{
		flex:1,
		flexDirection:'row',
		alignItems: 'center',
		alignContent: 'center',
	}
})
