import React, {useState,useEffect} from 'react'
import {StyleSheet, View} from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Component
export default function SaveButton({onPress,...props}) {

	// Number of people saved event
	const [level,setLevel] = useState(props.level)

	// Track number of people saved event
	useEffect(()=>{ setLevel(props.level) },[props.level])

	// User saved event
	const [saved,setSaved] = useState(props.saved)

	// Track if user is going to event or not
	useEffect( () => { setSaved(props.saved) }, [props.saved] )

	// Loading
	const [loading,setLoading] = useState(props.loading)

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState(props.disabled)

	// Track disabled
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={disabled}
				onPress={onPress}
				type='clear'
				icon={<Icon name='star' size={24} color={disabled && 'grey' || saved && 'yellow'} type='feather'/> }
			/>
			<Paragraph>{ (level || level == 0)?level:'?' }</Paragraph>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignItems:'center'
	}
})
