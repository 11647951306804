import React, {useState,useEffect,useRef} from 'react'
import { Platform, StyleSheet, FlatList, TouchableOpacity} from 'react-native'

// Derive screen size
import { useRenderArea } from 'hook/utilities'

// Front event card
import EventFront from 'view/EventFrontView'

// Back event card
import EventBack  from 'view/EventBackView'


// Event front and back
import EventFrontBack from 'view/EventFrontBackView'

// Cards view
export default function EventCards({navigation, _FlatList, onRefresh, onNext, loading, ...props}) {

	// get the available render area
	var {width, height} = useRenderArea()

	// Keep track of current index
	const [ currentIndex, setCurrentIndex ] = useState(0)

	// On view changed
	const onViewRef = React.useRef(({ changed, viewableItems })=> {
		// Log
		// console.log('onViewableItemsChanged', viewableItems)
		//
		
		// console.log(Object.getOwnPropertyNames(changed[0]))
		// console.log(Object.getOwnPropertyNames(changed[0]['item']))

		// console.log(Object.getOwnPropertyNames(changed).filter(function (x) {
		// 	return typeof changed[x] === 'function'
		// }))

		// Set the current index
		if(viewableItems && viewableItems.length > 0)
			setCurrentIndex(viewableItems[0]?.index)
	})

	// View config
	const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 50 })

	// Render screen
	return (
		<>
			<FlatList ref={_FlatList}
				style={styles.FlatList}
				contentContainerStyle={styles.FlatListContainer}
				initialNumToRender={100}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				disableVirtualization={Platform.OS === "web"}
				directionalLockEnabled={true}
				scrollEventThrottle={250}
				pagingEnabled={true}
				data={props?.events}
				keyExtractor={event => event._id}
				renderItem={ ({item}) =>
					<EventFrontBack 
						navigation={navigation}
						width={width}
						height={height}
						loggedin={props?.loggedin}
						user={props?.user}
						event={item}
					/>
				}
				getItemLayout={(_,index) => ({
					length: height, 
					offset: height * index, 
					index
				})}
				refreshing={loading || false}
				onRefresh={onRefresh}
				onEndReachedThreshold={15}
				onEndReached={ onNext }
				// removeClippedSubviews
				maxToRenderPerBatch={100}
				viewabilityConfig={viewConfigRef.current}
				onViewableItemsChanged={onViewRef.current}
			/>
			{ Platform.OS === 'web' &&
				<TouchableOpacity
					style={[styles.nav,{
						width,
						cursor:'s-resize',
					}]}
					onPress={async () => {
						if(_FlatList && _FlatList.current) {
							_FlatList.current.scrollToIndex({index:currentIndex+1})
						}
					}}
				/>
			}
		</>
	)
}

// Styles
const styles = StyleSheet.create({
	FlatList: {
		height:'100%',
	},
	FlatListContainer: {
	},
	nav: {
		flex:1,
		flexDirection:'row',
		position: 'absolute',
		bottom:0,
		// alignItems:'top',
		// alignContent:'right',
		height:30,
		// backgroundColor: 'red',
	}, 
})


