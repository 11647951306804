// Import core
import api from './core'

// Fetch register request
export async function fetchRegisterRequest({email,password,firstname,lastname}) {
	return (await api.post('/register',{email,password,firstname,lastname})).data.code
}

// Fetch register validation
export async function fetchRegisterVerify({code}) {
	await api.get(`/register/${code}`)
	return true
}

// Fetch password reset request
export async function fetchResetRequest({email}) {
	return (await api.post('/reset',{email})).data.code
}

// Fetch password reset verification
export async function fetchResetVerify({code, password}) {
	await api.post(`/reset/${code}`, {password})
	return true
}

// Fetch the token
export async function fetchToken({email,password}) {
	// Fetch valid token from server
	return (await api.post(`/token`,{email,password})).data
}

// clear token
export async function clearToken() {
	try { 
		await api.get('/token/clear') 
		return true
	} 
	catch(err) {
		return false
	}
}
