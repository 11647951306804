// React Core
import React, {useEffect} from 'react'

// Navigation
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator, TransitionPresets, CardStyleInterpolators } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

// Components
import EventFeed from 'screen/EventFeed'
import Saved from 'screen/Saved'
import Event from 'screen/Event'
import Login from 'screen/Login'
import Register from 'screen/Register'
import Reset from 'screen/Reset'
import Settings from 'screen/Settings'
import Profile from 'screen/Profile'

// Authentication hook
import {useLoggedin} from 'hook/authentication'

// Icons
import { Icon } from 'react-native-elements'

// Stack navigation
const Stack = createStackNavigator()

// Tabs
const Tab = createBottomTabNavigator()

// Tabs screen
function Tabs({navigation, route}) {
	// Set navigation options
	useEffect(()=>{navigation?.setOptions({headerShown: false,})},[])
	// Authentication hook
	const {data:loggedin} = useLoggedin()
	// Screen options
	const ScreenOptions = {
		// unmountOnBlur: true,
		tabBarShowLabel: false,
		tabBarActiveTintColor: 'white',
		tabBarInactiveTintColor: 'grey',
		gestureEnabled:true,
		gestureDirection:'horizontal',
		headerMode:'float',
	}
	// Feed tab styling
	const FeedTabOptions = {
		tabBarLabel: 'explore',
		tabBarIcon: ({focused,color,size}) => (<Icon name="cards" type='material-community' size={size} color={color}/>)
	}
	// Saved tab styling
	const SavedTabOptions = {
		tabBarLabel: 'saved',
		tabBarIcon: ({focused,color,size}) => (<Icon name="book" type='entypo' size={size} color={color}/>)
	}
	// Saved tab styling
	const ProfileTabOptions = {
		tabBarLabel: 'profile',
		tabBarIcon: ({focused,color,size}) => (<Icon name="user" type='feather' size={size} color={color}/>)
	}
	// Render screen
	return (
		<Tab.Navigator screenOptions={ScreenOptions}>
			<Tab.Screen name="Feed" component={EventFeed} options={FeedTabOptions}/>
			<Tab.Screen name="Saved" component={loggedin?Saved:Login} options={SavedTabOptions}/>
			<Tab.Screen name="Profile" component={loggedin?Profile:Login} options={ProfileTabOptions}/>
		</Tab.Navigator>
	)
}

// Wrapper
export default function Feed() {
	// Authentication hook
	const {data:loggedin} = useLoggedin()
	// Animation config
	const AnimationConfig = {
		animation: 'spring',
		config: {
			stiffness: 1000,
			damping: 500,
			mass: 3,
			overshootClamping: true,
			restDisplacementThreshold: 0.01,
			restSpeedThreshold: 0.01,
		}
	}
	// Screen options
	const ScreenOptions = {
		...TransitionPresets.SlideFromRightIOS,
		unmountOnBlur: true,
		tabBarShowLabel: false,
		tabBarActiveTintColor: 'white',
		tabBarInactiveTintColor: 'grey',
		gestureDirection:'horizontal',
		headerMode:'float',
		gestureEnabled:true,
	}
	// Render component
	return (
		<SafeAreaProvider style={{BackHandler:'black'}}>
			<NavigationContainer initialRouteName='Create' >
				<Stack.Navigator screenOptions={ScreenOptions}>
					<Stack.Screen name="Tabs" component={Tabs} />
					<Stack.Screen name="Event" component={loggedin?Event:Login} />
					<Stack.Screen name="Login" component={Login} />
					<Stack.Screen name="Register" component={Register} />
					<Stack.Screen name="Reset" component={Reset} />
					<Stack.Screen name="Settings" component={Settings} />
				</Stack.Navigator>
			</NavigationContainer>
		</SafeAreaProvider>
	)
}
