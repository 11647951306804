// React Core
import {useState, useEffect } from 'react'

// React Native
import { useWindowDimensions, StatusBar } from 'react-native'

// Bottom tab bar height
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs'

// Use header height
import { useHeaderHeight } from '@react-navigation/elements'

// Derive screen dimensions
export function useRenderArea() {
	// Get window dimensions
	const { width:windowWidth, height:windowHeight } = useWindowDimensions()

	// // Status bar height
	// const [statusHeight, setStatusHeight] = useState(0)

	// // Track status bar height
	// useEffect(() => setStatusHeight(StatusBar.currentHeight || 0), [StatusBar.currentHeight])

	// Get the bottom bar height 
	try { 
		var bottomHeight = useBottomTabBarHeight() 
	} catch(err) {
		var bottomHeight = 0
	}

	// Get the header height
	try { 
		var headerHeight = useHeaderHeight() 
	} catch (err) {
		var headerHeight = 0
	}

	// Return new state
	return {width:windowWidth, height:windowHeight-bottomHeight-headerHeight}
}

// Debounce function
export function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debounced, setDebounced] = useState(value);
	// Track value and delay
	useEffect(() => {
			// Update debounced value after delay
			const handler = setTimeout(() => setDebounced(value), delay)
			// Return
			return () => clearTimeout(handler)
	}, [value, delay])
	// Return the debounced state value
	return debounced
}
