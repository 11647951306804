import React, {useState,useEffect} from 'react'
import {StyleSheet, View } from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Icons
import { Feather } from '@expo/vector-icons'

// Component
export default function SaveButton({onPress,...props}) {

	// Number of people who like event
	const [level,setLevel] = useState()

	// Track number of people who like event
	useEffect(()=>setLevel(props.level),[props.level])

	// User liked event
	const [liked,setLiked] = useState(false)

	// Track liked
	useEffect( () => {setLiked(props.liked)}, [props.liked] )

	// Loading
	const [loading,setLoading] = useState()

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState()

	// Track loading
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={disabled}
				onPress={onPress}
				type='clear'
				icon={<Icon 
					name='heart' size={24} 
					color={disabled && 'darkgrey' || liked && '#eb82fd'}
					type='feather'
				/>}
			/>
			<Paragraph>{ (level || level == 0)?level:'?' }</Paragraph>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignItems:'center',
	},
	circleContainer: {
		backgroundColor:'black',
		borderRadius:50,	
		padding:10,
		borderWidth:0.65,
		borderColor:'white',
	},
	textBottom:{
		fontSize:14,
		fontWeight:'800',
	},
});
