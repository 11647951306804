import React, {useState, useEffect, useRef} from 'react';
import { StyleSheet, Text, Alert, ScrollView } from 'react-native'
import { Input, Button} from 'react-native-elements';

// Containers
import { Wrapper } from 'style/containers'

// Menu in the header
import HeaderMenu from 'component/HeaderMenu'

// Code verifier component
import CodeVerifier from 'component/CodeVerifier'

// Authentication hooks
import {useResetRequest, useResetVerify} from 'hook/authentication'

// Render area hook
import { useRenderArea } from 'hook/utilities'

// Icons
import { Entypo} from '@expo/vector-icons';

// Request user account page
function Request({navigation, style, onRequest}) {

	// User email
	const [email, setEmail] = useState('')

	// User password
	const [password, setPassword] = useState('')

	// Repeated user password
	const [repeat, setRepeat] = useState('')

	// Valid input
	const [valid, setValid] = useState(false)

	// Validate input
	useEffect(() => setValid(
		email && password && password === repeat
	),[email,password,repeat])

	// Reset request hook
	const {mutate:resetRequest, data:resetCode, isLoading: loading} = useResetRequest({ 
		onSuccess:() => onRequest(password)
	})

	// Render request page
	return (
		<Wrapper style={style}>
			<Input label='Email'
				placeholder='Email'
				leftIcon={<Entypo name="mail" size={18} color="gray" />}
				onChangeText={text => setEmail(text)}
			/>
			<Input label='New password'
				placeholder='Password'
				leftIcon={<Entypo name="lock" size={18} color="gray" />}
				onChangeText={text => setPassword(text)}
				secureTextEntry={true}
			/>
			<Input label="Repeat new password"
				placeholder='Password'
				leftIcon={<Entypo name="lock" size={18} color="gray" />}
				onChangeText={text => setRepeat(text)}
				secureTextEntry={true}
			/>
			<Button 
				title='Send' 
				type='outline'
				loading={ loading }
				disabled={!valid}
				onPress={ () => resetRequest({email}) }
			/>
			<Text>{resetCode}</Text>
		</Wrapper>
	)
}

// Verify user account page
function Verify({navigation, style, ...props}) {
	// User password
	const [password, setPassword] = useState()
	// Track password property
	useEffect( () => setPassword(props.password), [props.password] )
	// Register verify hook
	const {mutateAsync:resetVerify} = useResetVerify()

	// Render verify page
	return (
		<Wrapper style={style}>
			<CodeVerifier
				placeholder="Password reset verification code"
				onVerify={ async code => {
					// Verify code
					const done = await resetVerify({code,password})
					// Check if successful
					if(done) {
						navigation?.popToTop()
						return true
					}
					return false
			}}/>
			<Text>We have send you an email with an reset code.</Text>
			<Text> </Text>
			<Text>Scan the QR code or copy your reset code to change your account password.</Text>
		</Wrapper>
	)
}

// Register user account screen
function Reset({navigation}) {

	// Get the dimensions of the area to render on
	const {width, height} = useRenderArea()

	// ScrollView element reference
	const refScrollView = useRef()

	// User password
	const [password, setPassword] = useState("")

	// Navigation options
	useEffect(()=>{
		navigation?.setOptions({
			title: 'Reset password',
			headerRight: () => (<HeaderMenu navigation={navigation}/>)
		})
	},[])

	// Render register screen
	return (
		<ScrollView pagingEnabled={true} horizontal={true} style={styles.ScrollView} ref={refScrollView}>
			<Request style={{width,height}} onRequest={ password => {
				setPassword(password)
				refScrollView.current.scrollTo({x:width})
			}}/>
			<Verify style={{width,height}} navigation={navigation} password={password}/>
		</ScrollView>
	)
}

// Export view
export default Reset

// Styles
const styles = StyleSheet.create({})
