// Import core
import api from './core'

// Fetch device location
export async function fetchLocation() {
	// Get public ip
	const {ip} = (await axios.get('https://api.ipify.org?format=json')).data
	// Use geoip to derive location
	const position = (await api.post('/service/location',{ip})).data
	// Construct location
	const location = position?{coords:position}:undefined
	// Return location
	if(location)
		return location
}

// Fetch device location
export async function fetchFreegeoip() {
	// Get position
	const {latitude,longitude} = (await axios.get('https://api.freegeoip.app/json/?apikey=118157c0-8f56-11ec-a673-a51cdc0f2e04')).data
	// Construct location
	const location = (latitude && longitude)?{coords:{latitude,longitude}}:undefined
	// Return location
	if(location)
		return location
}

// Fetch forward geo code place -> location
export async function fetchGeocodeForward(query) {
	// Post query
	return (await api.post('/service/geocode/forward', {query})).data
}

// Fetch backward geo code location -> place
export async function fetchGeocodeBackward(latitude,longitude) {
	// Post query
	return (await api.post('/service/geocode/backward', {latitude,longitude})).data
}
