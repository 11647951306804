import { Platform, Alert } from 'react-native'


// Cancel event dialog
export function cancelEventAlert( onPress ) {
	// Web implementation
	if(Platform.OS === 'web') {
		console.log('WEB IMPLEMENTATION OF ALERT')
		// Start dialog box
		const confirmed = confirm("Are you sure you want to cancel this event?");
		// Confirmed trigger
		if(confirmed) onPress()
	}
	// Mobile implementation
	else {
		Alert.alert(
			"Are you sure",
			"Are you sure you want to cancel this event?",
			[
				{
					text:"yes",
					onPress
				},
				{ text:"no" }
			]
		)
	}
}
