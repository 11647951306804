// React Core
import React, {useEffect} from 'react'

// Navigation
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

// Components
import Login from 'screen/Login'
import Register from 'screen/Register'
import Reset from 'screen/Reset'
import Settings from 'screen/Settings'
import Profile from 'screen/Profile'

// Icons
import { Icon } from 'react-native-elements'

// Authentication hook
import {useLoggedin} from 'hook/authentication'

// Tabs
const Tab = createBottomTabNavigator()

// Tabs screen
function Tabs({navigation, route}) {
	// Set navigation options
	useEffect(()=>{navigation?.setOptions({headerShown: false,})},[])
	// Authentication hook
	const {data:loggedin} = useLoggedin()
	// Screen options
	const ScreenOptions = {
		tabBarShowLabel: false,
		tabBarActiveTintColor: 'white',
		tabBarInactiveTintColor: 'grey',
	}
	// Profile tab styling
	const ProfileTabOptions = {
		tabBarIcon: ({focused,color,size}) => (<Icon name="user" type='entypo' size={size} color={color}/>)
	}
	// Saved tab styling
	const LoginTabOptions = {
		tabBarIcon: ({focused,color,size}) => (<Icon name="login" type='entypo' size={size} color={color}/>)
	}
	// Render screen
	return (
		<Tab.Navigator screenOptions={ScreenOptions}>
			<Tab.Screen name="Profile" component={loggedin?Profile:Login} options={ProfileTabOptions}/>
		</Tab.Navigator>
	)
}

// Stack navigation
const Stack = createStackNavigator();

// Wrapper
export default function Auth() {

	// Render component
	return (
		<SafeAreaProvider style={{BackHandler:'black'}}>
			<NavigationContainer initialRouteName='Create'>
				<Stack.Navigator>
					<Stack.Screen name="Tabs" component={Tabs} />
					<Stack.Screen name="Register" component={Register} />
					<Stack.Screen name="Reset" component={Reset} />
					<Stack.Screen name="Settings" component={Settings} />
				</Stack.Navigator>
			</NavigationContainer>
		</SafeAreaProvider>
	)
}
