import React, {useState,useEffect} from 'react'

import { Text, Platform, StyleSheet, Share, View} from 'react-native'

// Clipboard
import * as Clipboard from 'expo-clipboard'

// Modal
import Modal from 'component/Modal'

// Typography
import { Paragraph } from 'style/typography'

// Containers
import { Wrapper } from 'style/containers'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Component
export default function ShareButton(props) {

	// Shares
	const [shares,setShares] = useState(props.shares)

	// Track shares
	useEffect(() => setShares(props.shares), [props.shares])

	// Loading
	const [loading,setLoading] = useState(props.loading)

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState(props.disabled)

	// Track disabled
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Modal shown or not
	const [show, setShow] = useState(false)

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={disabled}
				onPress={async () => {
					// setShow(!show)
					props.onShare()
				}}
				type='clear'
				icon={<Icon name='share' size={24} color={disabled && 'grey'} type='entypo'/> }
			/>
			<Paragraph>{ (shares || shares == 0)?`${shares}x`:'?' }</Paragraph>
			<Modal
				visible={show} 
				onRequestClose={ () => setShow(false) }
				>
				<Wrapper>
					<Button 
						icon={<Icon name='whatsapp' type='material-community' size={20} />}
						type='outline' 
						title=" Whatsapp"
					/>
					<Button 
						icon={<Icon name='instagram' type='material-community' size={20} />}
						type='outline' 
						title=" Instagram"
					/>
					<Button 
						icon={<Icon name='facebook' type='material-community' size={20} />}
						type='outline' 
						title=" Facebook"
					/>
					<Button 
						icon={<Icon name='twitter' type='material-community' size={20} />}
						type='outline' 
						title=" Twitter"
					/>
					<Button 
						icon={<Icon name='snapchat' type='material-community' size={20} />}
						type='outline' 
						title=" Snapchat"
					/>
					<Button 
						icon={<Icon name='linkedin' type='material-community' size={20} />}
						type='outline' 
						title=" Linkedin"
					/>
					<Button 
						icon={<Icon name='share' type='material-community' size={20} />}
						type='outline' 
						title=" Link"
						onPress={ () => props.onShare() }
					/>
				</Wrapper>
			</Modal>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignItems:'center',
	},
})


					// // Ask for information
					// const url = await onShare()
					// // Trigger share
					// if(Platform.OS !== 'web')
					// 	Share.share({title:url, message:url, url:url})
					// // Copy to clipboard
					// Clipboard.setString(url);
