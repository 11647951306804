import React, {useState,useEffect} from 'react'
import { StyleSheet, View } from 'react-native'

// Modal
import Modal from 'component/Modal'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Input, Button, Icon } from 'react-native-elements'

// Date time picker
import DateTimePicker from '@react-native-community/datetimepicker'

// Time library
import moment from 'moment'

// Component
export default function DurationPicker(props) {

	// Start time
	const [start, setStart] = useState(null)

	// Track start time
	useEffect(() => setStart(props.start? new Date(props.start):undefined), [props.start])

	// Duration
	const [duration, setDuration] = useState(null)

	// Track duration
	useEffect(() => setDuration(props.duration), [props.duration])

	// End
	const [end, setEnd] = useState()

	// Track end
 	useEffect(() => setEnd(new Date(start?.getMilliseconds() + duration)), [start,duration])

	// Days
	const [days, setDays] = useState('0')

	// Hours
	const [hours, setHours] = useState('0')

	// Minutes
	const [minutes, setMinutes] = useState('0')

	// Track days hours minutes
	useEffect(() => {
		// Total duration
		const duration = moment.duration(props.duration)
		// Days
		const days = duration.days()
		// Number of days
		setDays(days?String(days):'')
		// Remove days
		duration.subtract(moment.duration(days,'days'))
		// Hours
		const hours = duration.hours()
		// Set hours
		setHours(hours?String(hours):'')
		// Remove minutes
		duration.subtract(moment.duration(hours,'hours'))
		// Minutes
		const minutes = duration.minutes()
		// Set minutes
		setMinutes(minutes?String(minutes):'')
	}, [props.duration])

	// Show the modal
	const [showModal,setShowModal] = useState(false)

	// Show date picker
	const [showDatePicker, setShowDatePicker] = useState(false)

	// Show time picker
	const [showTimePicker, setShowTimePicker] = useState(false)

	// Render component
	return (
		<View style={styles.dateContainer}>
			<Button
				type='clear'
				icon={
					<View style={styles.buttonContainer}>
						<Icon name="clock-start" size={18} color={(duration)?'white':'grey'} type='material-community'/>
						<Paragraph> {(duration)?moment.duration(duration).humanize():'-'} </Paragraph>
					</View>
				}
				onPress={_ => setShowModal(true)}
				disabled={!start}
			/>

			<Modal 
				visible={showModal} 
				animationType='slide'
				onRequestClose={() => { 
					// Hide modal
					setShowModal(false)
					// Trigger change event
					props.onChange( Number(days)*24*60*60*1000 + Number(hours)*60*60*1000 + Number(minutes)*60*1000 )
				}}>
				<View style={styles.modalContainer}>
					<Paragraph style={styles.txt}>How long will the even take in days, hours and minutes.</Paragraph>
					<View style={styles.row}>
						<Button
							type='clear'
							disabled={!start}
							icon={ <Icon name='calendar' size={25} color='grey' type='ionicon'/> }
							onPress={() => setShowDatePicker(true)}
						/>
						<Button
							type='clear'
							disabled={!start}
							icon={ <Icon name='time' size={25} color='grey' type='ionicon'/> }
							onPress={() => setShowTimePicker(true)}
						/>
						<Input
							containerStyle={styles.input}
							disabled={!start}
							label="days" 
							value={days}
							placeholder={`0`} 
							keyboardType="numeric" 
							onChange={e => {setDays(e.nativeEvent.text)}}/>
						<Icon name='add' size={20} color='grey' type='ionicon'/>
						<Input
							containerStyle={styles.input}
							disabled={!start}
							label="hours" 
							value={hours}
							placeholder={`0`} 
							keyboardType="numeric" 
							onChange={e => setHours(e.nativeEvent.text)}/>
						<Icon name='add' size={20} color='grey' type='ionicon'/>
						<Input
							containerStyle={styles.input}
							disabled={!start}
							label="minutes" 
							value={minutes}
							placeholder={`0`} 
							keyboardType="numeric" 
							onChange={e => setMinutes(e.nativeEvent.text)}/>
					</View>
					<Paragraph style={styles.txt}>This will be used to set the end date and time of your event.</Paragraph>
				</View>
			</Modal>

			{showDatePicker && <DateTimePicker
				minimumDate={start}
				value={end || start || new Date()}
				mode='date'
				onChange={(_,selectedDate) => {
					// Hide modal
					setShowDatePicker(false)
					// Trigger change event
					if(selectedDate)
						props.onChange(selectedDate)
				}}
			/>}

			{showTimePicker && <DateTimePicker
				minimumDate={start}
				value={end || start || new Date()}
				mode='time'
				onChange={(_,selectedDate) => {
					// Hide modal
					setShowTimePicker(false)
					// Trigger change event
					if(selectedDate)
						props.onChange(selectedDate)
				}}
			/>}

		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	},
	modalContainer:{
		margin:10,
	},
	txt: {
		margin:10,
		color:'grey',
	},
	row:{
		flexDirection:'row',
		alignItems: 'center',
	},
	input:{
		flex:1,
	}
})
