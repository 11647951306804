import React, { useState, useEffect, createElement } from 'react';
import { Platform, StyleSheet, View} from 'react-native';

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements';

// Date time picker
import DateTimePicker from '@react-native-community/datetimepicker'

// Modal
import Modal from 'component/Modal'

// Component
export default function TimePicker(props) {

	// Date object
	const [date, setDate ] = useState('')

	// Track date
	useEffect(() => setDate(props.date?new Date(props.date):''), [props.date])

	// Show modals
	const [showModal,setShowModal] = useState(false)

	// Render web component
	const web = (
		<View style={styles.dateContainer}>
			<Button
				{...props}
				type='clear'
				icon={
					<View style={styles.buttonContainer}>
						<Icon name="clockcircleo" size={18} color={date?'white':'grey'} type='antdesign'/>
						{Platform.OS === 'web' && createElement('input',{
							type: 'time',
							value: date && date.toLocaleTimeString('en-GB',{hour:'2-digit',minute:'2-digit'}),
							onChange: (event) => {
								// Extract hours and minutes
								const [hours,minutes] = event.target.value.split(':')
								// Create new date
								const newdate = new Date(
									date.getFullYear(), 
									date.getMonth(), 
									date.getDate(), 
									Number(hours), 
									Number(minutes), 
								)
								// Trigger date update
								props.onChange(event.target.value && newdate)
							},
							style: {
								color:'white',
								backgroundColor:'transparent',
								borderWidth:0,
								textAlign:'center'
							}
						})}
					</View>
				}
				onPress={() => setShowModal(true)}
			/>
		</View>
	)

	// Render mobile component
	const mobile = (
		<View style={styles.dateContainer}>
			<Button
				{...props}
				type='clear'
				icon={
					<View style={styles.buttonContainer}>
						<Icon name="clockcircleo" size={18} color={date?'white':'grey'} type='antdesign'/>
						<Paragraph> {date?date?.toLocaleTimeString([],{timeStyle:'short'}):'-'}</Paragraph>
					</View>
				}
				onPress={() => setShowModal(true)}
			/>

			{ showModal && 
				<DateTimePicker
					minimumDate={new Date()}
					value={date || new Date()}
					mode='time'
					onChange={(_,selectedDate) => {
						// Hide modal
						setShowModal(false)
						// Trigger change event
						if(selectedDate)
						props.onChange(selectedDate)
					}}
			/>
			}
		</View>
	)

	// Return correct one
	return (Platform.OS === 'web')? web:mobile
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
		alignContent: 'center',
		alignItems:'center',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	}
})
