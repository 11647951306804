import React, {useState,useEffect} from 'react';
import { StyleSheet, View, Text} from 'react-native';

// Component
export default function Stamp(props) {

	// Dynamic styling
	const [dynStyle, setDynStyle] = useState({
		transform:[{rotate:props.angle || '-45deg'}],
		left: props.x || 0,
		top:  props.y || 0,
		backgroundColor: props.color || 'red'
	})

	// Watch state
	useEffect(() => setDynStyle({
		transform:[{rotate:props.angle || '-45deg'}],
		left: props.x || 0,
		top:  props.y || 0,
		backgroundColor: props.color || 'red'
	}), [props.angle, props.x, props.y, props.color])

	// Render component
	return (
		<View style={[styles.banner, props.style, dynStyle]}>
			{props.children && 
				<Text style={[styles.text,props.textstyle]}>{props.children}</Text>
			}
		</View>
	)

}

// Static styling
const styles = StyleSheet.create({
	banner:{
		flex:0,
		justifyContent:'center',
		alignItems:'center',
		alignContent: 'center',
	},
	text: {
		fontWeight:'bold', 
		fontSize:15, 
		color:'white',
		// alignText:'center',
	}
})
