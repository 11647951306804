// React Core
import React, {useState, useEffect} from 'react'

// React Native
import {StyleSheet, View, Text} from 'react-native'

// React Native Elements
import { Button, Icon } from 'react-native-elements'

// Icons
import { Entypo } from '@expo/vector-icons'
import { Ionicons } from '@expo/vector-icons'

// Expo
import * as Linking from 'expo-linking'

// Navigation
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { BottomTabBarHeightContext } from '@react-navigation/bottom-tabs';
import { NavigationContainer, useLinkTo } from '@react-navigation/native'

// Screens
import EventFeed from 'screen/EventFeed'
import EventCreate from 'screen/EventCreate'
import Profile from 'screen/Profile'
import Settings from 'screen/Settings'
import Saved from 'screen/Saved'
import Friends from 'screen/Friends'
import Login from 'screen/Login'
import Register from 'screen/Register'

// Components
import SettingsButton from 'component/SettingsButton'

// Expo prefix
const prefix = Linking.makeUrl('/')

// Tab Screen
const Tab = createBottomTabNavigator()
const TabScreen = ({navigation}) => {
	// Events
	const [events, setEvents] = useState([])

	// Load events from backend
	useEffect(()=>{Backend.feedRefresh()},[])

	// Temporary testing
	useEffect(()=>{
		// Backend.login('test@test.mail','password')
		// Backend.clearCredentials()
	},[])

	// Navigator
	const linkTo = useLinkTo();

	return (
		<>
			{/* TAB NAVIGATOR BOTTOM TABS*/}
			<Tab.Navigator
				initialRouteName = 'Events'
				tabBarOptions={{
					style:{
						// position:'absolute',
						borderTopWidth:0,
						borderTopColor:'white',
						elevation:0,
						backgroundColor: 'rgba(0,0,0,1)',
						// marginHorizontal:10,
						// height:50,
					},
					tabStyle:{},
					activeTintColor:'white',
					inactiveTintColor:'rgba(235,235,235,0.9)',
					showLabel:false,
				}}
				screenOptions={({route}) => ({
					tabBarIcon: ({color, size}) => {
						if(route.name == 'Events')
							return <Icon name='cards' type='material-community' size={size} color={color}/>
						if(route.name == 'Saved')
							return <Icon name="book" type='entypo' size={size} color={color}/>
						if(route.name == 'Friends')
							return <Icon name="people" type='material' size={size} color={color}/>
						if(route.name == 'Profile')
							return <Icon name="person" type='material' size={size} color={color}/>
						if(route.name == 'Create')
							return <Icon name="megaphone" type='entypo' size={size+8} color={color}/>
					}
				})}>
				{/* EVENT FEED SCREEN */}
				<Tab.Screen name='Events' component={EventFeed}/>
				{/* SAVED EVENTS SCREEN */}
				<Tab.Screen name='Saved' component={Saved}
					listeners={() => ({
						tabPress: event => {
							if(!Backend.loggedin) {
								event.preventDefault()
								linkTo('/login')
								// navigation.navigate('login')
							}
						}
					})}
				/>
				{/* CREATE EVENT SCREEN */}
				<Tab.Screen name='Create' children={() =>
					<EventCreate onCreate={ newEvent => Backend.createEvent(newEvent)}  />
					}
					listeners={() => ({
						tabPress: event => {
							if(!Backend.loggedin) {
								event.preventDefault()
								linkTo('/login')
								// navigation.navigate('login')
							}
						}
					})}
				/>
				{/* FRIENDS SCREEN */}
				<Tab.Screen name='Friends' component={Friends}
					listeners={() => ({
						tabPress: event => {
							if(!Backend.loggedin) {
								event.preventDefault()
								linkTo('/login')
								// navigation.navigate('login')
							}
						}
					})}
				/>
				{/* PROFILE SCREEN */}
				<Tab.Screen name='Profile' component={Profile}
					listeners={() => ({
						tabPress: event => {
							if(!Backend.loggedin) {
								event.preventDefault()
								linkTo('/login')
								// navigation.navigate('login')
							}
						}
					})}
				/>
			</Tab.Navigator>
			</>
	)
}

// Stack navigation
const Stack = createStackNavigator();
export default function Routes() {

	// Add links
	const linking = {
		prefix: [prefix],
		config: {
			screens: {
				Events: "events",
				Saved: "saved",
				Create: "create",
				Friends: "friends",
				Profile: "profile"
			}
		}
	}

	return (
		<SafeAreaProvider style={{BackHandler:'black'}}>
			<NavigationContainer initialRouteName='Create' linking={linking}>
				<Stack.Navigator>
					{/* MAIN SCREEN */}
					<Stack.Screen name="Main" options={{headerShown: false}} component={TabScreen} />
					{/* LOGIN SCREEN */}
					<Stack.Screen name="Login" component={Login}/>
					{/* REGISTER SCREEN */}
					<Stack.Screen name='Register' component={Register}/>
					{/* SETTINGS SCREEN */}
					<Stack.Screen name='Settings' component={Settings}/>
				</Stack.Navigator>
			</NavigationContainer>
		</SafeAreaProvider>
	)
}

// Styles
const styles = StyleSheet.create({
	rowContainer:{
		flexDirection:'row',
	},
})

					// <Stack.Screen name="add"
					// 	options={({navigation}) => ({
					// 		title:'Host event',
					// 		headerRight: _ =>
					// 		<View style={styles.rowContainer}>
					// 			<Button type='clear'
					// 				icon={<Ionicons name="checkmark-sharp" size={24} color="black"/>}
					// 				onPress={_ => { console.log(event); Backend.createEvent(event); event = new Event(); navigation.navigate('main') }}
					// 				/>
					// 			<Button type='clear'
					// 				icon={<Ionicons name="close" size={24} color="black"/>}
					// 				onPress={_ => { event = new Event(); navigation.goBack() }}
					// 				/>
					// 		</View>
					// 	})}
					// 	children={_ => <EventCreate event={event} onChange={newEvent => event = newEvent}  />}
					// 	/>

