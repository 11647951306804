import React, {useState, useEffect, useLayoutEffect} from 'react'
import { StyleSheet} from 'react-native'
import { Input, Button} from 'react-native-elements'

// Containers
import { Wrapper } from 'style/containers'

// Components
import HeaderMenu from 'component/HeaderMenu'

// Icons
import { Entypo } from '@expo/vector-icons';

// Authentication hook
import {useLogin, useLoggedin} from 'hook/authentication'

// View
function Login({navigation, route}) {
	// Email
	const [email,setEmail] = useState('')

	// Password
	const [password,setPassword] = useState('')

	// Valid
	const [valid, setValid] = useState(false)

	// Track validity
	useEffect(() => setValid(email && password),[email,password])

	// Login user hook
	const {mutate:login, isLoading:loginLoading} = useLogin()

	// Login hook
	const {data:loggedin} = useLoggedin()

	// Set navigation options
	useLayoutEffect(()=>{
		navigation?.setOptions({
			title: 'Account login',
			headerRight: () => (<HeaderMenu navigation={navigation}/>)
		})
	},[])

	// Render component
	return (
		<Wrapper>
			<Input label='Email'
				placeholder='email'
				leftIcon={<Entypo name="mail" size={18} color="gray" />}
				onChangeText={text => setEmail(text)}
			/>
			<Input label='Password'
				placeholder='password'
				leftIcon={<Entypo name="lock" size={18} color="gray" />}
				onChangeText={text => setPassword(text)}
				secureTextEntry={true}
			/>
			<Button 
				type='outline'
				title='Login'
				disabled={ !valid || loggedin }
				loading = { loginLoading }
				onPress={ async () => { 
				login({
						email:email.trim(),
						password:password.trim()
					})
				}}
			/>
			<Button title='Forgot your password?' type='clear'
				onPress={()=>navigation.navigate('reset')}
				/>
			<Button title='Not registered yet?' type='clear'
				onPress={()=>navigation.navigate('register')}
				/>
		</Wrapper>
	)
}

// Export view
export default Login

// Styles
const styles = StyleSheet.create({})
