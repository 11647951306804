import React, {useState,useEffect} from 'react';
import {StyleSheet, View} from 'react-native';

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Component
export default function AttendButton({onPress,...props}) {

	// Event capacity
	const [capacity,setCapacity] = useState(props.capacity)

	// Track capacity
	useEffect(()=>setCapacity(props.capacity),[props.capacity])

	// Number of people going to event
	const [level,setLevel] = useState(props.level)

	// Track number of people going to event
	useEffect(()=>setLevel(props.level),[props.level])

	// User attending event
	const [attending,setAttending] = useState(false)

	// Track if user is going to event or not
	useEffect( () => {setAttending(props.attending)}, [props.attending] )

	// Loading
	const [loading,setLoading] = useState(props.loading)

	// Track loading
	useEffect(()=>{setLoading(props.loading)}, [props.loading])

	// Disabled
	const [disabled,setDisabled] = useState(props.disabled)

	// Track loading
	useEffect(()=>{setDisabled(props.disabled)}, [props.disabled])

	// Render component
	return (
		<View style={styles.container}>
			<Button
				loading={loading}
				disabled={disabled}
				onPress={onPress}
				type='clear'
				icon={<Icon 
					size={24} 
					name={attending && 'user-check' || 'user-plus'} 
					color={disabled && 'darkgrey' || attending && 'lightgreen'} type='feather'/> }
			/>
			<Paragraph>{ (level || level == 0)?level:'?' }/{ (capacity || capacity ==0)? capacity:'?' }</Paragraph>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container:{
		alignItems:'center',
	},
	circleContainer: {
		backgroundColor:'black',
		borderRadius:50,	
		padding:10,
		borderWidth:0.65,
		borderColor:'white',
	},
	textBottom:{
		fontSize:14,
		fontWeight:'800',
	},
});
