import React, {useState,useEffect} from 'react'

import { StyleSheet} from 'react-native';

// Style view
import {View} from 'react-native'


// Wrapper
export function Wrapper(props) {
	// Render component
	return (
		<View {...props} style={[WrapperStyle.core, props.style]}></View>
	)
}


// Wrapper Style
const WrapperStyle = StyleSheet.create({
	core:{
		margin:0,
		padding:10,
	}
})

// Row
export function Row(props) {
	// Render component
	return (
		<View {...props} style={[RowStyle.core,props.style]}></View>
	)
}

// Row Style
const RowStyle = StyleSheet.create({
	core:{
		flexDirection: 'row'
	}
})

// Spread in row
export function SpreadRow(props) {
	// Render component
	return (
		<View {...props} style={[SpreadRowStyle.core, props.style]}></View>
	)
}

// Row Style
const SpreadRowStyle = StyleSheet.create({
	core:{
		flexDirection: 'row',
		justifyContent:'space-around',
	}
})

// Spread as column
export function Spread(props) {
	// Render component
	return (
		<View {...props} style={[SpreadStyle.core, props.style]}></View>
	)
}

// Column Style
const SpreadStyle = StyleSheet.create({
	core:{
		justifyContent:'space-between',
	}
})

// Center content
export function Center(props) {
	// Render component
	return (
		<View {...props} style={[CenterStyle.core, props.style]}></View>
	)
}

// Center horizontal style
const CenterStyle = StyleSheet.create({
	core:{
		alignItems:'center',
	}
})

// Max width
export function MaxWidth(props) {
	// Render component
	return (
		<View {...props} style={[{maxWidth:props.max || 600}, MaxWidthStyle.core, props.style]}></View>
	)
}

// Center horizontal style
const MaxWidthStyle = StyleSheet.create({
	core:{
		flex:1,
	}
})
