// Import axios
import axios from 'axios'

// Config constants
import constants from 'config/constants'

// Async storage
import AsyncStorage from '@react-native-async-storage/async-storage'

// Create axios client
const api = axios.create()

// Axios request interceptor
api.interceptors.request.use(async req => {
	// Get the api url
	const url = await AsyncStorage.getItem('@join/auth/api') || constants.apiurl
	// Set base url
	req.baseURL = url || req.baseURL
	// Get state
	const token  = await AsyncStorage.getItem('@join/auth/token')
	const expire = await AsyncStorage.getItem('@join/auth/expire')
	// Check token
	if(token && Date.now() < Date.parse(expire))
		req.headers.common['x-auth-token'] = token
	else {
		// Remove invalid token
		await AsyncStorage.removeItem('@join/auth/token')
		await AsyncStorage.removeItem('@join/auth/expire')
	}
	// Pass on the request
	return req
})

// Axios response error interceptor
api.interceptors.response.use(res => res, async err => {
	// Check token
	if(err?.response?.status == 401) {
		// Remove invalid token
		await AsyncStorage.removeItem('@join/auth/token')
		await AsyncStorage.removeItem('@join/auth/expire')
	}
	// Pass on the error
	throw err
})

// Export the api
export default api 
