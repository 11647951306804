import React,{useState,useEffect} from 'react'
import { StyleSheet, Text, View } from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// Elements
import { Icon } from 'react-native-elements'

// Event preview
export default function InfoBar(props) {

	// User
	const [user, setUser] = useState(props.user)

	// Track user
	useEffect(() => setUser(props.user),[props.user])

	// Event
	const [event, setEvent] = useState(props.event)

	// Track event
	useEffect(() => setEvent(props.event),[props.event])

	// User saved event
	const saved = user?.saves.includes(event?._id)

	// User going to event
	const going = user?.going.includes(event?._id)

	// User liked event
	const liked = user?.likes.includes(event?._id)

	// User created event
	const created = user?.events?.includes(event?._id)

	// Render component
	return (
		<View style={[styles.container, props.style]}>
			<Icon name='star' size={18} color={saved && 'yellow'} type='feather'/>
			<Paragraph> {event?.saves}  </Paragraph>
			{!event.source && <Icon name={going?'user-plus':'user-x'} size={18} color={going && 'lightgreen'} type='feather'/>}
			{!event.source && <Paragraph> {event.going.length}/{event.capacity}  </Paragraph>}
			{event?.memory && <Icon name='heart' color={liked && '#eb82fd'} size={18} type='feather'/>}
			{event?.memory && <Paragraph> {event?.likes}  </Paragraph>}
			{!event?.upcoming && <Icon name='comment-multiple-outline' size={18} type='material-community'/>}
			{!event?.upcoming && <Paragraph> {event?.posts?.length}  </Paragraph>}
			<Icon name='share' size={18} type='entypo'/>
			<Paragraph> {event?.shares}  </Paragraph>
			<Icon name={event?.exclusive?'eye-off':'eye'} size={18} type='ionicon'/>
			<Paragraph>  </Paragraph>
			{ created && <Icon name='flag' size={18} type='feather'/>}
			<Paragraph>  </Paragraph>
			{ event?.source && <Icon name='external-link' size={18} type='feather'/>}
		</View>
	)
}

const styles = StyleSheet.create({
	container:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		textAlign:'center',
		color:'white',
	},
})
