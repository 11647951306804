// React
import React, {useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'

// Image component
import { Image } from 'react-native-elements'

// Banners
import Stamp from 'component/Stamp'

// Components
import InfoBarTop from 'component/InfoBarTop'
import InfoBarBottom from 'component/InfoBarBottom'

// Front event view
export default function EventFrontView(props) {

	// Event
	// const [event, setEvent] = useState(props.event)

	// Track event
	// useEffect(() => setEvent(props.event),[props.event])

	const event = props.event

	// User
	// const [user, setUser] = useState(props.user)

	// Track user
	// useEffect(() => setUser(props.user), [props.user])

	const user = props?.user

	// Style
	// const [style, setStyle] = useState(props.style)
	
	// Track style
	// useEffect(() => setStyle(props.style),[props.style])

	const style = props?.style

	// Render component
	return (
		<View style={{...styles.container,...style}}>
			<Image
				containerStyle={styles.image}
				source={{uri:event?.flyer}}
				resizeMode={'contain'}
			/>
			<InfoBarTop user={user} event={event} style={{marginBottom:10, marginTop:10}}/>
			<InfoBarBottom event={event} user={user} style={{marginBottom:10}}/>
			{ event?.cancelled && 
				<View style={styles.stampwrapper}>
					<Stamp 
						style={styles.stamp}
						textstyle={styles.stamptext}
						// angle='45%'
						// x='15%' 
						// y='45%' 
					>
						CANCELLED
					</Stamp>
				</View>
			}
		</View>
	)
}

// Styling
const styles = StyleSheet.create({
	container: {
		flex:1,
		width:'100%',
		height:'100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		flex:1,
		width:'100%',
		height:'100%'
	}, 
	stampwrapper:{
		position:'absolute',
		width:'100%',
		height:'100%',
		justifyContent:'center',
		alignItems:'center'
	},
	stamp: {
		padding:40
	},
	stamptext: {
		fontSize:40,
	}
})
