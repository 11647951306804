// Import core
import api from './core'
	
// Fetch event
export async function fetchEvent(id) {
	return (await api.get(`/event/${id}`)).data
}

// Fetch events
export async function fetchEvents(ids) {
	return (await api.post(`/events`,ids)).data
}

// Post new event
export async function fetchPostEvent(event) {
	return (await api.post('/event', event)).data
}

// Patch existing event
export async function fetchPatchEvent(event,id) {
	return (await api.patch(`/event/${id}`,event)).data
}

// Patch flyer image Blob/File
export async function fetchPatchFlyer(image,id) {
	// Create form
	const form = new FormData()
	// Append file
	form.append('flyer', image)
	// Send form
	return (await api.patch(`/event/${id}/flyer`, form, {headers:{"content-type":"multipart/form-data"}})).data
}

// Cancel event by id
export async function fetchCancel(id) {
	return (await api.delete(`/event/${id}`)).data
}

// Save an event
export async function fetchSave(id) {
	return (await api.post(`/event/${id}/save`)).data
}

// Unsave an event
export async function fetchUnSave(id) {
	return (await api.delete(`/event/${id}/save`)).data
}

// Attend event
export async function fetchAttend(id) {
	return (await api.post(`/event/${id}/attend`)).data
}

// Pass event
export async function fetchUnAttend(id) {
	return (await api.delete(`/event/${id}/attend`)).data
}

// Like event
export async function fetchLike(id) {
	return (await api.post(`/event/${id}/like`)).data
}

// Pass event
export async function fetchUnLike(id) {
	return (await api.delete(`/event/${id}/like`)).data
}

// Event URL
export async function fetchEventUrl(id) {
	// Get web url
	const web = await AsyncStorage.getItem('@join/auth/web')
	// Construct url
	return `${web}/event/${id}`
}

// Record event share
export async function fetchShare(id,os,platform) {
	return (await api.post(`/event/${id}/share`,{os,platform})).data
}

// Add a new post
export async function fetchPost(eid, text) {
	return (await api.post(`/event/${eid}/post`,{text})).data
}

// (dis)like a post
export async function setPostLike(eid, pid, sign) {
	return (await api.patch(`/event/${eid}/post/${pid}/like/${sign}`)).data
}

// Add new reply
export async function fetchReply(eid, pid, text) {
	return (await api.post(`/event/${eid}/post/${pid}/reply`,{text})).data
}
