// React
import React, {useState, useEffect } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'

// Containers
import {Wrapper, Row, Center, SpreadRow, MaxWidth } from 'style/containers'

// Typography
import {Title, Subtitle, Paragraph} from 'style/typography'

// Image component
import { Avatar } from 'react-native-elements'

// Buttons
import TicketButton from 'component/TicketButton'
import ShareButton from 'component/ShareButton'
import LocationButton from 'component/LocationButton'
import SaveButton from 'component/SaveButton'
import AttendButton from 'component/AttendButton'
import SourceButton from 'component/SourceButton'
import LikeButton from 'component/LikeButton'
import PostsButton from 'component/PostsButton'

// Components
import InfoBarTop from 'component/InfoBarTop'
import InfoBarBottom from 'component/InfoBarBottom'

// Authentication hooks
// import {useLoggedin} from 'hook/authentication'

// Events hook
import { useSave, useUnSave, useAttend, useUnAttend, useLike, useUnLike, useShare } from 'hook/events'

// Address formatting
import { formatAddress } from 'function/events'

// Event back style
export default function EventBackView(props) {

	const user = props?.user

	const loggedin = props?.loggedin

	const event = props?.event
	
	// Event save
	const { mutate:save, isLoading:saveLoading } = useSave()

	// Event unsave
	const { mutate:unsave, isLoading:unsaveLoading } = useUnSave()

	// Event attend
	const { mutate:attend, isLoading: attendLoading } = useAttend()

	// Event pass
	const { mutate:pass, isLoading:passLoading } = useUnAttend()

	// Event like
	const { mutate:like, isLoading:likeLoading } = useLike()

	// Event unlike
	const { mutate:unlike, isLoading:unlikeLoading } = useUnLike()

	// Share event
	const { mutate:share, isLoading:shareLoading } = useShare()

	// Render component
	return (
		<Wrapper style={{width:props?.style?.width,height:props?.style?.height}}>
				<Center>
					<Title style={{textAlign:'center'}}>{event?.title}</Title>
					<Subtitle>{event?.subtitle}</Subtitle>
					<Paragraph>{event?.location?.address && formatAddress(event.location.address)}</Paragraph>
					<InfoBarTop user={user} event={event} style={{marginBottom:10, marginTop:10}}/>
					<InfoBarBottom event={event} user={user} style={{marginBottom:10}}/>
					<Row style={{flexWrap:'wrap', justifyContent:'center'}}>
						{event?.hosts?.map((host,idx) => 
						<Row style={{margin:5, alignItems:'center'}} key={`host:${idx}`}>
								<Avatar
									rounded 
									size={32}
									source={host.user?.avatar && {uri:host?.user?.avatar}}
									icon={!host?.user?.avatar && {name:'person', type:'material', size:21}}
									containerStyle={{backgroundColor:'grey', marginRight:5}}
									onPress={host?.user?._id && (() => props.navigation.navigate('profile', {id:host?.user?._id}))}
								/>
							{host?.user && <Paragraph> 
								{host?.user?.firstname} {host?.user?.lastname} 
							</Paragraph>}
							{host?.ext  && <Paragraph> 
								{host?.ext}
							</Paragraph>}
							</Row>
						)}
					</Row>
					<Row style={{flexWrap:'wrap', justifyContent:'center'}}>
						{event?.artists?.map((artist,idx) => 
							<Row style={{margin:5, alignItems:'center'}} key={`artist:${idx}`}>
								<Avatar
									rounded 
									size={32}
									source={artist.user?.avatar && {uri:artist?.user?.avatar}}
									icon={!artist?.user?.avatar && {name:'person', type:'material', size:21}}
									containerStyle={{backgroundColor:'grey', marginRight:5}}
									onPress={artist?.user?._id && (() => props.onUser(artist?.user?._id))}
								/>
							{artist?.user && <Paragraph> 
								{artist?.user?.firstname} {artist?.user?.lastname} 
							</Paragraph>}
							{artist?.ext  && <Paragraph> 
								{artist?.ext} 
							</Paragraph>}
							</Row>
						)}
					</Row>
				</Center>
				<ScrollView style={{marginVertical:10,flex:1}}>
					<Paragraph>{event?.description}</Paragraph>
				</ScrollView>
				<View style={{width:'100%', alignItems:'center'}}>
					<SpreadRow style={{width:'100%', maxWidth:'100%'}}>
						<LocationButton location={event?.location?.coordinates}/>
						{event.upcoming && <TicketButton 
							disabled={!event?.ticket}
							price={event?.ticket?.price} 
							url={event?.ticket?.url}
						/>}
						{event.upcoming && <SaveButton 
							level={event?.saves}
							disabled={!loggedin || !event.upcoming || saveLoading || unsaveLoading || attendLoading || passLoading || event?.cancelled}
							saved={user?.saves.includes(event?._id)}
							onPress={ () => {
								if(user.saves.includes(event?._id))
								unsave(event?._id)
								else
								save(event?._id)
							}}
						/>}
						{event.upcoming && !event.source && <AttendButton 
							level={ event?.going?.length } 
							capacity={ event?.capacity }
							disabled={!loggedin || !event.upcoming || attendLoading || passLoading || saveLoading || unsaveLoading || event?.cancelled}
							attending={ user?.going.includes(event?._id) }
							onPress={ async () => {
								if(user.going.includes(event?._id))
								pass(event?._id)
								else
								attend(event?._id)
							}}
						/>}
						{ event.source && <SourceButton
							source={event.source}
						/>}
						{event.memory && <LikeButton 
							level={event?.likes}
							disabled={!loggedin || event.locked || likeLoading || unlikeLoading || event?.cancelled || !user?.going.includes(event?._id)}
							liked={user?.likes.includes(event?._id)}
							onPress={ async () => {
								if(user.likes.includes(event?._id))
								unlike(event?._id)
								else 
								like(event?._id)
							}}
						/>}
						{!event.upcoming && <PostsButton 
							event={event}
							// disabled={!event.upcoming}
							// liked={user?.likes.includes(event?._id)}
						/>}
						<ShareButton
							shares={event?.shares}
							disabled={shareLoading}
							onShare={async () => share(event)}
						/>
					</SpreadRow>
				</View>
		</Wrapper>
	)
}
