// React Core
import React, {useEffect} from 'react'

// React native
import { Platform } from 'react-native'

// Expo linking
import * as Linking from 'expo-linking';

// Navigation
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NavigationContainer, createNavigationContainerRef, useLinkTo } from '@react-navigation/native'
import { createStackNavigator, TransitionPresets} from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

// Root navigator
import { navigationRef, navigate } from 'helper/RootNavigator'

// Elements
import { ThemeProvider, Icon } from 'react-native-elements'

// Theme
import {navigationTheme, elementsTheme} from 'style/theme'

// Containers
import {Wrapper, Row, Center, SpreadRow, MaxWidth } from 'style/containers'

// Screens
import EventFeed from 'screen/EventFeed'
import Saved from 'screen/Saved'
import Event from 'screen/Event'
import SetEvent from 'screen/SetEvent'
import Login from 'screen/Login'
import Register from 'screen/Register'
import Reset from 'screen/Reset'
import Settings from 'screen/Settings'
import Profile from 'screen/Profile'
import Friends from 'screen/Friends'
import TOS from 'screen/TOS'
import Permissions from 'screen/Permissions'

// Authentication hook
import {useLoggedin} from 'hook/authentication'

// Notification effect
import { useNotificationEffect } from 'hook/notifications'

// Notifications
import * as Notifications from 'expo-notifications'

// Configure notifications
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
   }),
})

// Tabs
const Tab = createBottomTabNavigator()

// Tabs screen
function Tabs({navigation}) {
	// Set navigation options
	useEffect(()=>{navigation?.setOptions({headerShown: false,})},[])
	// Authentication hook
	const {data:loggedin} = useLoggedin()
	// Screen options
	const ScreenOptions = {
		tabBarHideOnKeyboard: true,
		unmountOnBlur: false,
		tabBarShowLabel: false,
		tabBarActiveTintColor: 'white',
		tabBarInactiveTintColor: 'grey',
		gestureEnabled:true,
		gestureDirection:'horizontal',
		headerMode:'float',
	}
	// Create event tab styling
	const CreateEventTabOptions = {
		tabBarLabel: 'create',
		tabBarIcon: ({focused,color,size}) => (<Icon name="library-add" type='material' size={size} color={loggedin?color:'#111'}/>)
	}
	// Feed tab styling
	const FeedTabOptions = {
		tabBarLabel: 'explore',
		tabBarIcon: ({focused,color,size}) => (<Icon name="cards" type='material-community' size={size} color={color}/>)
	}
	// Saved tab corepi cor
	const SavedTabOptions = {
		tabBarLabel: 'saved',
		tabBarIcon: ({focused,color,size}) => (<Icon name="star" type='materialcommunity' size={0} color={loggedin?color:'#111'}/>)
	}
	// Saved tab styling
	const FriendsTabOptions = {
		tabBarLabel: 'friends',
		tabBarIcon: ({focused,color,size}) => (<Icon name="people" type='material' size={0} color={color}/>)
	}
	// Saved tab styling
	const ProfileTabOptions = {
		tabBarLabel: 'profile',
		tabBarIcon: ({focused,color,size}) => (<Icon name="person" type='material' size={size} color={color}/>)
	}
	// Render screen
	return (
		<Tab.Navigator 
			screenOptions={ScreenOptions}
		>
			<Tab.Screen name="feed" component={EventFeed} options={FeedTabOptions}/>
			<Tab.Screen name="friends" component={Friends} options={FriendsTabOptions}
				listeners={() => ({
					tabPress: event => event.preventDefault()
				})}
			/>
			<Tab.Screen name="create" component={Login} options={CreateEventTabOptions}
				listeners={() => ({
					tabPress: event => {
						event.preventDefault()
						if(loggedin)
							navigation.navigate('create')
					}
				})}
			/>
			<Tab.Screen name="saved" component={loggedin?Saved:Login} options={SavedTabOptions}
				listeners={() => ({
					tabPress: event => {
							event.preventDefault()
					}
				})}
			/>
			<Tab.Screen name="user" component={loggedin?Profile:Login} options={ProfileTabOptions}/>
		</Tab.Navigator>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////



// Stack navigation
const Stack = createStackNavigator()

// Screen stack
function Stacks(props) {
	// Notification handler
	useNotificationEffect( notification => {
		// Extract content
		const content = notification?.request?.content
		// Handle reminder
		if(content?.data?.type === 'reminder')
			navigate('event',{id:content.data.eid})
	})

	// Screen options
	const ScreenOptions = {
		...TransitionPresets.SlideFromRightIOS,
		unmountOnBlur: false,
		tabBarShowLabel: false,
		tabBarActiveTintColor: 'white',
		tabBarInactiveTintColor: 'grey',
	}

	// Authentication hook
	const {data:loggedin} = useLoggedin()

	// Render component
	return (
		<Stack.Navigator screenOptions={ScreenOptions} initialRouteName={props.initialRouteName || "HOME"} >
			<Stack.Screen name="HOME" component={Tabs} />
			<Stack.Screen name="event" component={Event} unmountOnBlur={true}/>
			<Stack.Screen name="create" component={SetEvent} />
			<Stack.Screen name="login" component={Login} />
			<Stack.Screen name="register" component={Register} />
			<Stack.Screen name="reset" component={Reset} />
			<Stack.Screen name="settings" component={Settings} />
			<Stack.Screen name="tos" component={TOS} />
			<Stack.Screen name="profile" component={Profile} />
			{ Platform.OS !== 'web' && <Stack.Screen name="Permissions" component={Permissions} /> }
		</Stack.Navigator>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////

// Navigation container
export default function Create() {
	// Linking
	const linking = {
		prefixes: [ 
			Linking.createURL('/'), 
			'https://join.com', 
			'https://unstable.join.com', 
			'https://stable.join.com',
		],
		config: {
			initialRouteName: 'HOME',
			screens: {
				HOME: {
					screens: {
						Feed: 'feed',
						Saved: 'saved',
						Friends: 'friends',
						Profile: 'profile',
					}
				},
				Register:'register',
				Event:'event/:id',
				SetEvent:{
					path:'set/:id',
					parse:{id: id => /^[a-z0-9]{24}$/.test(id)?id:undefined}
				}
			}
		},
	}
	// Render component
	return (
		<ThemeProvider theme={elementsTheme}>
			<SafeAreaProvider style={{BackHandler:'black'}}>
					<NavigationContainer linking={linking} theme={navigationTheme} ref={navigationRef}  >
						<Stacks initialRouteName='HOME'/>
					</NavigationContainer>
			</SafeAreaProvider>
		</ThemeProvider>
	)
}
