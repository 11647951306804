// Import
import * as core from './core'
import * as authentication from './authentication'
import * as event from './event'
import * as user from './user'
import * as feed from './feed'
import * as service from './service'
import * as telemetry from './telemety'

// Export
export default {
	...core,
	...authentication,
	...event,
	...user,
	...feed,
	...service,
	...telemetry,
}
