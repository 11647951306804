// React native
import { StyleSheet, Text, View} from 'react-native'

// React native theme
export const nativeTheme = {
}

// React navigation theme
export const navigationTheme = {
	// dark: true,
	colors: {
		primary: 'rgb(200,200,200)',
		background: 'rgb(2,2,2)',
		card: 'black',
		text: 'white',
		border: 'rgb(0,0,0)',
		notification: 'black',
	},
}

// Navigation theme
export const elementsTheme = {
	colors: {
		primary: 'grey',
		secondary: 'grey',
		white: 'black',
		black: 'white',
		grey0: '#ccc',
		grey1: '#bbb',
		grey2: '#aaa',
		grey3: '#999',
		grey4: '#333',
		grey5: '#111',
		greyOutline: '#333',
		searchBg: '#111',
		success: 'green',
		error: 'red',
		warning: 'yellow',
		divider: 'grey',
	},
	Icon: {
		color:'white',
		disabledStyle: {
			backgroundColor:'transparent',
			color:'#333'
		}
	},
	Image:{
		placeholderStyle: {
			backgroundColor:'transparent'
		},
		ImageCacheEnum:'reload'
	},
	Card: {
		style:{
			alignContent: 'center',
			alignItems: 'center',
		},
		containerStyle: {
			backgroundColor: 'black',
			borderColor: 'gray',
			borderWidth: 2,
			color:'red',
			borderRadius: 5,
		},
		wrapperStyle: {
			alignContent: 'center',
			alignItems: 'center',
		}
	},
	Input: {
		style: {},
		containerStyle: {},
		inputStyle: {},
		errorStyle: {},
	},
	Button: {
		disabledStyle:{
			borderColor:'#333',
		},
		disabledTitleStyle:{
			color:'#333'
		}
	},
	CheckBox:{
		containerStyle: {
			backgroundColor:'transparent',
			borderWidth:0,
		}
	},
	Avatar:{
		containerStyle:{
			backgroundColor:'#BDBDBD'
		}
	},
	ListItem: {
		Subtitle: {
			color:'green'
		}
	},
	ListItemSubtitle: {
		style:{
			color: 'white'
		}
	}
	
}
