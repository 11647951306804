// Toast messages
import Toast from 'react-native-toast-message'

// Error handler
function onError(error) {
	// Get status
	const status = error?.response?.status
	// Get data
	const data = error?.response?.data
	// Don't generate toast on 401
	if(status == 401) return
	// Set the toast type
	var type = (status < 500)?'info':'error'
	// Set header
	var header = data?.name
	// Set body
	var body = data?.errors[0]?.message 
	// Create toast message
	Toast.show({type, text1:header, text2:body})
	// Return
	return
}

// Retry handler
function retry(count,error) {
	// Get status
	const status = error?.response?.status
	// Don't retry client side error codes
	if(status >= 400 && status <= 499) return false
	// Max retry count
	if(count >= 3) return false
	// Default
	return true
}

// react query options
const options = {
	// Default query options
	queries:{ 
		onError, 
		retry,
		// notifyOnChangeProps:['tracked']
	},
	// Default mutation options
	mutations:{ 
		onError, 
		retry,
		// notifyOnChangeProps:['tracked']
	},
}

// Export the options
export default options
