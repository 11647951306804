// React
import { useEffect }  from 'react'

// Notifications
import * as Notifications from 'expo-notifications'

// Notification hook
export function useNotificationEffect( handler, dependencies=[]) {
	// Get last notification response
	const response = Notifications.useLastNotificationResponse()
	// Create the effect
	useEffect( () => {
		// Extract notification data
		const notification = response?.notification
		// Run handler
		if(notification)
			handler(notification)
	},[response, ...dependencies])
}
