import React, {useLayoutEffect} from 'react'
import { StyleSheet, Text, ScrollView, View} from 'react-native'

// Containers
import { Wrapper } from 'style/containers'

// Typography
import { Title, Subtitle, Paragraph } from 'style/typography'

// Component
export default function TOS({navigation}) {

	// Set header
	useLayoutEffect(() =>
		navigation?.setOptions({
			title: 'Terms and Conditions'
		})
	,[])

	const app  = 'Join'
	const corp = 'Join B.V.'
	const adres = 'Zeeburgerdijk 123 te Amsterdam'
	const kvk = '+31 687394855 (Join)'
	const info = 'info@join.nl'
	const help = 'help@join.nl'
	const vers = 'april 2022'

	// Render component
	return (
		<Wrapper>  
			<ScrollView>
				<Subtitle> Gebruikers voorwaarden </Subtitle>
				<Paragraph>
					Deze Algemene Voorwaarden zijn van {corp}, gevestigd aan de {adres}, geregistreerd bij de Kamer van Koophandel onder nummer {kvk}.
				</Paragraph>
				<Paragraph>
					Deze gebruiksvoorwaarden zijn van toepassing op het gebruik van de {app}-applicatie via web en app (Platform) en de {app} website (de Website). Lees daarom voordat je gebruik maakt van {app} aandachtig de onderstaande punten door. Naast deze gebruiksvoorwaarden is ook de Privacy Statement van toepassing. Door {app} te gebruiken, ga je automatisch akkoord met deze gebruiksvoorwaarden en de Privacy Statement.
				</Paragraph>
				<Paragraph>
					Als je vragen hebt over deze gebruiksvoorwaarden, stuur ons dan een e-mail naar {info}.
				</Paragraph>
				<Paragraph> Versie: {vers} </Paragraph>

				<Subtitle>Richtlijnen</Subtitle>
				<Paragraph>
					We vinden het belangrijk dat iedereen zich thuis voelt en op een prettige manier met elkaar omgaat binnen {app}. Om een goede sfeer te behouden, hebben we een aantal algemene regels opgesteld over hoe we met elkaar willen omgaan. Dit zijn enkel richtlijnen waarmee we het juist gedrag willen sturen om de sfeer en omgangsvorm op het platform te waarborgen. 
				</Paragraph>
				<Paragraph>
					• Wees jezelf – Iedereen is welkom bij {app}. Durf jezelf te zijn en te laten zien waar je voor staat.
				</Paragraph>
				<Paragraph>
					• Respecteer elkaar – Laat een ander in zijn waarde en omarm de verschillen. Zo zorgen we samen voor een goede sfeer.
				</Paragraph>
				<Paragraph>
					• Maak vriendschap – Leer nieuwe mensen kennen vanuit vriendschap. Gebruik {app} daarom niet voor dating.
				</Paragraph>
				<Paragraph>
					Voor we dieper ingaan op de algemene voorwaarden geven we hieronder eerst de 10 belangrijkst (gedrags)regels. Wanneer hier geen gehoor aan wordt gegeven behoudt {app} zich het recht je profiel of geplaatste informatie te blokkeren en/of te verwijderen.
				</Paragraph>


				<Subtitle> Gedragsregels </Subtitle>
				<Paragraph>
					1. Vul je profiel naar waarheid en volledig in: duidelijke profielfoto, omschrijving en interesses.
				</Paragraph>
				<Paragraph>
					2. {app} is geen dating platform, gebruik het hier dus ook niet voor!
				</Paragraph>
				<Paragraph>
					3. Het plaatsen van reacties is enkel bedoeld voor relevante zaken omtrent activiteiten en momenten. Dit is geen plek voor irrelevante zaken of persoonlijke discussies.
				</Paragraph>
				<Paragraph>
					4. Plaats geen beledigende, onfatsoenlijke of erotische berichten. {app} is ook geen plek voor religieuze uitingen.
				</Paragraph>
				<Paragraph>
					5. Het is niet toegestaan jezelf aan te melden voor activiteiten in de toekomst zonder dat je zeker weet aanwezig te zijn. Mocht je dit niet zeker weten, gebruik dan de ‘bewaar’ optie in de app.
				</Paragraph>
				<Paragraph>
					6. Afmelden voor een activiteit gebeurt niet later dan 24 uur van te voren. Als het regelmatig voorkomt dat je je te laat afmeldt, behoudt {app} zich het recht je account te pauzeren of deelname aan activiteiten tijdelijk niet mogelijk te maken.
				</Paragraph>
				<Paragraph>
					7. {app} accepteert geen spam, promoties of handelingen die voor {app} als concurrerend beschouwd kunnen worden. Zonder toestemming wordt elke vorm van promotie, reclame of activiteiten met persoonlijk gewin direct geblokkeerd en/of verwijderd.
				</Paragraph>
				<Paragraph>
					8. {app} is bedoeld voor het ondernemen van activiteiten met andere gebruikers. Het is dan ook niet toegestaan om oproepen en of vragen te plaatsen op het platform, anders dan activiteiten waar anderen fysiek of online aan kunnen deelnemen.
				</Paragraph>
				<Paragraph>
					9. {app} is speciaal ontwikkeld voor 50-plussers en dus alleen vanaf 50 jaar te gebruiken.
				</Paragraph>
				<Paragraph>
					10. Je lidmaatschap wordt na afloop van het afgesloten lidmaatschap maandelijks verlengd (zoals aangegeven bij afsluiten) indien je dit niet vroegtijdig stopzet. Wel ontvang je voortijdig een bericht voor het opnieuw afsluiten van het lidmaatschap.
				</Paragraph>

				<Subtitle> Algemene voorwaarden </Subtitle>
				<Paragraph>
					• Je bent verplicht een echte naam en identiteit te gebruiken op het platform. Hiervoor hebben we de volgende gegevens van je nodig: naam, e-mail adres, wachtwoord, geboortedatum, woonplaats, geslacht en profielfoto. Indien je een lidmaatschap afsluit hebben we ook je adresgegevens nodig. Bij gebruik van het Platform ga je akkoord met het opslaan van persoonlijke gegevens zoals beschreven in de Privacy Statement. We beheren en bewerken je persoonlijke gegevens in lijn met de Nederlandse Wet Bescherming Persoonsgegevens, de Nederlandse Telecommunicatiewet en de Europese Privacy Richtlijn – 95/46/EC.
				</Paragraph>
				<Paragraph>
					• Het is niet toegestaan meerdere malen te registreren met bijvoorbeeld andere e-mailadressen. Dit om misbruik van van het gratis proeflidmaatschap te voorkomen en/of een blokkade vanuit {app} te omzeilen.
				</Paragraph>
				<Paragraph>
					• Als er verdacht gedrag plaatsvindt via een account en het vermoeden bestaat dat het account zich als iemand anders voordoet heeft {app} het recht dit account te blokkeren.
				</Paragraph>
				<Paragraph>
					• {app} is gerechtigd een registratie en/of lidmaatschap met onmiddellijke ingang te onderbreken of beëindigen als een gebruiker zich niet houdt aan de bepalingen in deze overeenkomst.
				</Paragraph>

				<Paragraph>
					• Meld je af voor een activiteit wanneer je niet meer kunt deelnemen. Dit is voor zowel de organisator als overige deelnemers prettig en wel zo attent.
				</Paragraph>
				<Paragraph>
					• Gebruik het plaatsen van reacties waar het voor bedoeld is. Binnen activiteiten gaat dit over de desbetreffende activiteit en relevante zaken. Binnen momenten gaat dit over het specifieke moment. Het gebruik van reacties voor persoonlijke discussies of andere irrelevante zaken is niet toegestaan. {app} behoudt zich het recht reacties ten allen tijde en zonder overleg te verwijderen.
				</Paragraph>
				<Paragraph>
					• Plaats geen haatdragende of racistische opmerkingen.
				</Paragraph>
				<Paragraph>
					• {app} accepteert geen spam, promoties of handelingen die voor {app} als concurrerend beschouwd kunnen worden. Zonder toestemming wordt elke vorm van promotie of reclame direct geblokkeerd en verwijderd. Het is dan ook niet toegestaan het Platform zonder overleg met {app} te gebruiken voor commerciële boodschappen en of activiteiten met commercieel gewin.
				</Paragraph>
				<Paragraph>
					• Er kunnen activiteiten op het platform worden aangeboden van derde partijen. Deze samenwerkingen gaat {app} aan om ervoor te zorgen dat er genoeg en leuk aanbod is van activiteiten door het hele land. In sommige gevallen is dit een commerciële samenwerking met {app} en zal {app} er ook inkomsten uit genereren.
				</Paragraph>
				<Paragraph>
					• Alle contact met of deelname aan aanbiedingen van derde partijen valt buiten onze verantwoordelijkheid. Binnen de relatie tussen jou als {app}-gebruiker en de derde partij gelden dan ook de algemene voorwaarden en privacy voorwaarden van de derde partij.
				</Paragraph>
				<Paragraph>
					• Bij gebruik van activiteiten die worden georganiseerd door {app} en worden aangeboden via het Platform ga je akkoord dat {app} op geen enkele manier aansprakelijk gesteld kan worden voor enige schade als gevolg van de aangegane relatie met de derde partij of met {app} zelf.
				</Paragraph>
				<Paragraph>
					• {app} is niet verantwoordelijk voor mogelijk persoonlijke conflicten die ontstaan tussen gebruikers na totstandkoming van onderling contact. {app} kijkt in dit geval puur naar gebruik van het Platform en of regels worden overtreden. Als dit niet het geval is zal {app} geen actie ondernemen.
				</Paragraph>
				<Paragraph>
					• Deel nooit persoonlijke informatie. We gaan er vanuit dat je dit niet doet, maar voor de zekerheid herinneren we je hieraan. Deel nooit bankgegevens, wachtwoorden en andere vertrouwelijke informatie. Wees ook terughoudend met het delen van je adres en telefoonnummer.
				</Paragraph>
				<Paragraph>
					• Wees bewust van het feit dat informatie wordt gedeeld. Je moet je realiseren dat alles wat je upload in {app} in het publieke domein te zien is. Denk dus goed na wat voor informatie je deelt, bijvoorbeeld bij het invullen van het profiel. Wij zijn niet verantwoordelijk voor wat je deelt maar als het een van de genoemde voorwaarden schendt, zullen we je profiel blokkeren.
				</Paragraph>
				<Paragraph>
					• Wij zijn niet verplicht om te controleren hoe mensen {app} gebruiken. Echter zullen we op de best mogelijke manier in de gaten houden wat er gebeurt in de App.
				</Paragraph>
				<Paragraph>
					• Als iemand zich onbehoorlijk gedraagt of een van deze regels overtreedt, rapporteer deze persoon dan met de ‘rapporteer’ knop bij het profiel. Het is belangrijk dat je dit ook daadwerkelijk doet om {app} zo leuk mogelijk te houden. Als je ongewenst gedrag niet rapporteert zijn wij niet in staat stappen te ondernemen
				</Paragraph>


				<Subtitle>Eigendom</Subtitle>
				<Paragraph>
					• Alle (intellectuele) eigendomsrechten met gebruik tot de inhoud en gebruik van {app}, het Platform en de Website, behoren toe aan {app}. Dus dat betekent het logo, de ontwerpen, de inhoud, de recensies, etcetera. Als je dit op enigerlei wijze gebruikt in uitlatingen naar anderen, publiceert of anderszins bewerkt zonder onze toestemming, schend je onze eigendomsrechten en klagen we je hoogstwaarschijnlijk aan. Deze eigendomsrechten schendt je ook als je content van andere gebruikers inzet voor andere doeleinden. Doe dit dus vooral niet.
				</Paragraph>
				<Paragraph>
					• Je stemt ermee in dat {app} niet verantwoordelijk is voor de inhoud die op het Platform wordt geplaatst en dat niet mag worden verondersteld dat {app} dergelijke inhoud goedkeurt of aanbeveelt. Het kan zijn dat gebruikers {app}-momenten (foto’s) delen op het Platform. {app} is niet verplicht deze inhoud te screenen of te controleren en is daarom niet verantwoordelijk voor enige schending van de privacy of inbreuk op de intellectuele eigendomsrechten van derden. De gebruiker vrijwaart {app} ten aanzien van alle schade als gevolg van een inbreuk in het kader van intellectuele eigendomsrechten van derden, inclusief auteurs- en portretrechten. {app} is ten allen tijden gerechtigd inhoud te verwijderen indien dit nodig wordt geacht. Ben je het niet eens met enige inhoud op het Platform, neem dan contact met ons op.
				</Paragraph>

				<Subtitle>Updates</Subtitle>
				<Paragraph>
					Er vindt continue ontwikkeling plaats op het Platform, waardoor er regelmatig updates te vinden zijn in de App en Play Store. Wanneer {app} een update doorvoert, kan deze update voorwaarden stellen die afwijken van hetgeen is bepaald in deze gebruiksvoorwaarden. We stellen je vooraf in de gelegenheid om de update te weigeren. Door installatie van een update, ga je akkoord met deze afwijkende voorwaarden, welke dan ook deel uitmaken van deze gebruiksvoorwaarden.	</Paragraph>
				<Subtitle>Aansprakelijkheid</Subtitle>
				<Paragraph>
					• Als gebruiker van het Platform heb je de mogelijkheid om activiteiten aan te maken en om deel te nemen aan activiteiten. Deelname aan alle activiteiten aangeboden via het Platform is volledig voor eigen risico en {app} is niet aansprakelijk voor enige vorm van schade: ongevallen tijdens activiteit, verlies of diefstal van goederen, handelen of nalatigheid van andere gebruikers.
					<Paragraph>
					</Paragraph>
					• Hoewel wij zorgvuldig zijn met het samenstellen van de inhoud van het Platform en de Website, bieden wij geen enkele garantie met betrekking tot de juistheid of nauwkeurigheid van de gegevens of informatie beschikbaar via het Platform en de Website. In het geval dat het Platform of Website niet beschikbaar zijn door een technische storing of door gebruikte software of servers is {app} op geen enkele manier aansprakelijk en bestaat er geen recht op restitutie van reeds betaalde gelden.
				</Paragraph>
				<Paragraph>
					• {app} is niet aansprakelijk voor enige directe of indirecte schade of verliezen als gevolg van het gebruik van het Platform en de Website inclusief schade veroorzaakt door virussen en malware. {app} kan niet aansprakelijk worden gesteld voor verlies van data en gegevens. Hieronder vallen ook door gebruikers geplaatste foto’s, teksten en ander materiaal. De gebruiker vrijwaart {app} voor iedere aanspraak van derde partijen en personen voortvloeiende uit het gebruik van de App.
				</Paragraph>
				<Paragraph>
					• Je moet je realiseren dat wanneer je gebruik maakt van de App, dat de App constant op je telefoon staat. Wij zijn niet verantwoordelijk voor enig effect van de App op je telefoon. Wanneer je het accepteert om push-berichten te ontvangen, zijn wij niet verantwoordelijk voor enige kosten die dit met zich mee brengt via je provider.
				</Paragraph>

				<Subtitle>Overige zaken</Subtitle>
				<Paragraph>
					• {app} is een platform waar activiteiten georganiseerd worden waar andere leden aan meedoen. Het is niet toegestaan om activiteiten die via een ander platform lopen (WhatsApp, Facebook etc) te promoten binnen het {app} Platform. Wij behouden het recht om dit soort promotieactiviteiten te verwijderen.
				</Paragraph>
				<Paragraph>
					• Wij zijn op ieder moment gerechtigd om wijzigingen door te voeren in deze gebruiksvoorwaarden. Controleer dus regelmatig deze gebruiksvoorwaarden om op de hoogte te blijven van veranderingen. Aarzel niet om ons een e-mail te sturen waarom je het niet eens bent met onze gebruiksvoorwaarden, zodat wij nogmaals kunnen controleren of het de juiste voorwaarden zijn. Als je {app} blijft gebruiken, gaan wij ervan uit dat je akkoord bent met de gewijzigde gebruiksvoorwaarden en ze zal blijven volgen.
				</Paragraph>
				<Paragraph>
					• Indien een bepaling uit deze gebruiksvoorwaarden niet geldig mocht blijken te zijn, dan blijven de overige voorwaarden gewoon van toepassing.
				</Paragraph>
				<Paragraph>
					• Voor het goed functioneren van {app} maken wij gebruik van de diensten van derden. Deze partijen kunnen voorwaarden stellen aan het verkrijgen van het Platform, het gebruiken daarvan en aanverwante zaken. {app} is niet aansprakelijk voor enige problemen die voortkomen uit de gebruikte diensten van deze partijen.
					Op deze gebruiksvoorwaarden is Nederlands recht van toepassing. Eventuele geschillen worden voorgelegd aan de bevoegde rechter in Amsterdam.
				</Paragraph>

				<Subtitle>Contact</Subtitle>
				<Paragraph>
					We nemen de algemene regels & voorwaarden van {app} serieus en vragen jullie dan ook deze na te leven zodat we van {app} een een veilige en bijzondere plek kunnen maken waar iedereen zich thuis voelt. Tot slot zijn deze regels & voorwaarden continu in ontwikkeling dus we horen graag via {help} als je aanvullende vragen, suggesties of comments hebt.
				</Paragraph>
				<Paragraph>
					Dank voor het lezen van de gebruikersvoorwaarden en voor je hulp om van {app} een mooie {app} te maken!
				</Paragraph>
				<Paragraph>Team {app} </Paragraph>
			</ScrollView>
		</Wrapper>
	)
}

// Styles
const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor:'black'
	},
});
