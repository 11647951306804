import React, {useState,useEffect} from 'react';
import { StyleSheet, View} from 'react-native';

// Modal
import Modal from 'component/Modal'

// Typography
import {Paragraph} from 'style/typography'

// React native elements
import { Input, Button } from 'react-native-elements';

// Icons
import { Ionicons } from '@expo/vector-icons';

// Component
export default function CapacityPicker(props) {
	
	// Capacity
	const [capacity, setCapacity] = useState('')

	// Track capacity
	useEffect(() => setCapacity(props.capacity?String(props.capacity):''),[props.capacity])

	// Show modal
	const [showModal,setShowModal] = useState(false)

	// Render component
	return (
		<View style={styles.dateContainer}>
			<View style={styles.buttonContainer}>
				<Button
					type='clear'
					icon={
						<View style={styles.buttonContainer}>
							<Ionicons name="people-outline" size={18} color={capacity?'white':'grey'} />
							<Paragraph> {capacity || '-'}</Paragraph>
						</View>
					}
					onPress={_ => setShowModal(true)}
				/>
			</View>

			<Modal
				visible={showModal} 
				animationType='slide'
				onRequestClose={() => { 
					// Hide modal
					setShowModal(false) 
					// Trigger change event
					if(capacity)
						props.onChange(Math.max(5,Number(capacity)))
			}}>
				<View style={styles.modalContainer}>
					<Paragraph style={styles.txt}>Capacity, the maximum number of people you want to have at the event.</Paragraph>
					<Input 
						label="Capacity" 
						value={capacity}
						placeholder='20'
						keyboardType="numeric" 
						onChange={e=>setCapacity(e.nativeEvent.text)}
					/>
					<Paragraph style={styles.txt}>* The maximum capacity is not an absolute maximum. We take into account the fact some people might not show up and try to correct for this fact.</Paragraph>
				</View>
			</Modal>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
		alignContent: 'center',
		alignItems:'center',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	},
	modalContainer:{
		margin:10,
	},
	txt: {
		margin:10,
		color:'grey',
	}
})
