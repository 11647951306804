import React, {useState,useEffect} from 'react';
import { StyleSheet, View, Text} from 'react-native';
import {ModalPage, ModalBottom} from 'component/Modal'

import { Input, Button, Icon } from 'react-native-elements'
import DateTimePicker_ from '@react-native-community/datetimepicker'

// Component
export default function SettingsButton(props) {
	const [showModal,setShowModal] = useState(false)
	
	return (
		<Button
			type='clear'
			icon={<Icon name="settings" type='material' size={24} color="black" />}
			onPress={_ => props.onPress()}
		/>
	)
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	}
})
