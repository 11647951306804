import React, {useState,useEffect} from 'react'
import { StyleSheet, View, Platform} from 'react-native'
import { Image, Button} from 'react-native-elements'

// Modal
import Modal from 'component/Modal'

import { Entypo } from '@expo/vector-icons'; 

// Image picker
import * as ImagePicker from 'expo-image-picker';

// Image manipulator
import { manipulateAsync, SaveFormat} from 'expo-image-manipulator';

// Component
export default function FlyerPicker(props) {

	// Image file
	const [image, setImage] = useState(props.image)

	// Track image
	useEffect(() => setImage(props.image),[props.image])

	// Pick image
	async function pickImage(mode) {
		// Picker options
		const options = {
			mediaTypes: ImagePicker.MediaTypeOptions.Image,
			allowsEditing:true,
			quality:1,
		}

		// Gallery
		if(mode === 'gallery')
			var result = await ImagePicker.launchImageLibraryAsync(options)

		// Camera
		if(mode === 'camera')
			var result = await ImagePicker.launchCameraAsync(options)

		// Max size longest edge
		const size = 2048

		// Resize operation
		const resize = {width:result.width, height: result.height}

		// Calculate new width height
		if(result.height > result.width && result.height > size) {
			// Set height to max
			resize.height = size
			// Recalculate width
			resize.width = result.width/result.height * size
		}

		// Calculate new width height
		if(result.width > result.height && result.width > size) {
			// Set width to max
			resize.width = size
			// Recalculate height
			resize.height = result.height/result.width * size
		}

		// Resize image
		const image = await manipulateAsync(result.uri, [{resize}], {compress:0.5, format: SaveFormat.JPEG})

		// Send image
		props.onChange(image)

		// Hide modal
		setVisible(false)
	}

	// Overlay
	const [visible, setVisible] = useState(false)

	// Render component
	return (
		<View style={{...styles.container, width:props.width,height:props.height}}>
			<Image 
				containerStyle={[styles.image]}
				source={{uri:image}}
				onPress={() => {
					if(Platform.OS === 'web')
						pickImage('gallery')
					else
						setVisible(true)
				}}
			/>
			<Modal 
				visible={visible}
				animationType='slide'
				onRequestClose={() => setVisible(false)}
			>
				<View style={styles.modal}>
					<Button 
						icon={<Entypo name="image" size={15} color="grey" />}
						titleStyle={styles.buttonTitle}
						containerStyle={styles.buttonContainer} 
						buttonStyle={styles.button}
						type='outline' 
						title=' Galery'
						onPress={() => pickImage('gallery')}
					/>
					<Button 
						icon={<Entypo name="camera" size={15} color="grey" />}
						titleStyle={styles.buttonTitle}
						containerStyle={styles.buttonContainer} 
						buttonStyle={styles.button}
						type='outline' 
						title=' Camera'
						onPress={() => pickImage('camera')}
					/>
					<Button 
						icon={<Entypo name="edit" size={15} color="grey" />}
						titleStyle={styles.buttonTitle}
						containerStyle={styles.buttonContainer} 
						buttonStyle={styles.button}
						type='outline' 
						title=' Flyer maker'
					/>
				</View>
			</Modal>
		</View>
	)
}

// Styles
const styles = StyleSheet.create({
	container: {
		flex:0,
		borderWidth: 1,
		borderColor: 'grey',
		marginHorizontal: 10,
	},
	image:{
		flex:1,
	},
	modal:{
		margin:20,
	},
	button:{
		borderColor:'grey',
		color:'grey',
	},
	buttonContainer:{
		marginVertical:10,
		// marginHorizontal:20,
	},
	buttonTitle:{
		color:'grey'
	},
})
