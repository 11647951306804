// Callback memo
import { useMemo, startTrasition} from 'react'

// React query
import { useInfiniteQuery, useQueryClient } from 'react-query'

// Import api
import api from 'api'

// Import event functions
import { preProcessEventStatic, preProcessEventDynamic } from 'function/events'

// Location hook
import {useLocation} from 'hook/location'

// Fetch feed
async function feed(context,search,size,location) {
	// Extract latitude
	const latitude = location?.latitude
	// Extract longitude
	const longitude = location?.longitude
	// Extract pageParam
	const { pageParam } = context
	// Open feed
	if(!pageParam)
		return await api.fetchFeedPage(await api.fetchFeedOpen(search,latitude,longitude),size)
	// Fetch page
	return await api.fetchFeedPage(pageParam,size)
}

// Use the feed in components
export function useFeed(search,size=10,options) {
	// Get location
	const {data:location} = useLocation()
	// Get the query client
	const queryClient = useQueryClient()
	// Create query
	return useInfiniteQuery('feed', async context => {
			// Get the data
			const data = await feed(context,search,size,location?.coords)
			//  process events
			data.events = data?.events?.map(event => preProcessEventStatic(event))
			// Return events
			return data
		}, {
		// Refresh every ...
		staleTime: 20*60*1000,
		// cacheTime: 20*60*1000,
		// 
		getNextPageParam: lastPage => lastPage.next,
		// Flatten pages
		select: data => data.pages.flatMap(page => page.events
			.map((event,index) => ({...preProcessEventDynamic(event), _score:page.scores[index]}))
			.filter(event => !event.memory)),
		onSuccess: feed => {
			// Update query for events
			feed.forEach( event => queryClient.setQueryData(['event', event._id], event) )
		},
		...options
	})
}

export function useFeedMap(search,size=10,options) {
	// Get device location
	// Create query
	return useFeed(search,size,{
		// Sort by difference
		select: data => data.pages.flatMap(page => page.events
			.map(event => preProcessEventDynamic(event))
			.filter(event => !event.memory)),
		...options,
	})
}
