import { useMemo, useCallback, startTransition } from 'react'
import { Platform, Share} from 'react-native'
import * as Clipboard from 'expo-clipboard'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { reminder, clearAllReminders } from 'notification/reminder'
import { preProcessEventStatic, preProcessEventDynamic, postProcessEvent, sort } from 'function/events'

// Import api
import api from 'api'

// Use an event
export function useEvent(id,options) {
	// Create query
	return useQuery(['event',id], async () => 
		preProcessEventStatic(await api.fetchEvent(id)), 
		{
		//
		enabled: !!id,
		// Refresh every minute
		staleTime: 60*1000,
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Use multiple events
export function useEvents(ids = [], options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create query
	return useQuery(['events',ids], () => api.fetchEvents(ids), {
		enabled: !!ids,
		staleTime: 5*60*1000,
		placeholderData: [],
		onSuccess: events => {
			// Update query for events
			events.forEach( event => queryClient.setQueryData(['event', event._id], event) )
		},
		// Post process events
		select: events => events.map(event => preProcessEventDynamic(event)),
		// Overrides
		...options,
	})
}

// Set event
async function setEvent({event,flyer,id}) {
	// Process event
	event = postProcessEvent(event)
	// Patch event
	if(id && !flyer)
		return await api.fetchPatchEvent(event,id)
	// Patch event and flyer
	if(id && flyer) {
		var event = await api.fetchPatchEvent(event,id)
		var event = await api.fetchPatchFlyer(flyer,id)
		return event
	}
	// Create a new event
	var event = await api.fetchPostEvent(event,flyer)
	var event = await api.fetchPatchFlyer(flyer,event._id)
	return event
}

// Set or create an event
export function useSetEvent(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(setEvent, {
		onSuccess: (data) => {
			// Update events
			if(data?._id) queryClient.setQueriesData(['events'], events => {
				// Filter out relevant event
				const selected = events.filter(event => event._id === data._id)
				// Update event
				selected.forEach(event => Object.assign(event, data))
				// Return events
				return events
			})
			// Update the data on the event
			queryClient.setQueryData(['event', data._id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === data._id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === data._id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === data._id)?data:event)
					}))
				}))
		},
		// Post process events
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Cancel event
export function useCancel(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchCancel, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Save event hook
export function useSave(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchSave, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					if(events) return [...events, data] 
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map(event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
			// Update user
			queryClient.setQueryData(['user'], user => {
				// Add event to saved list
				user.saves.push(id)
				// Return user
				return user
			})
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Unsave event hook
export function useUnSave(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchUnSave, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.filter( event => event._id !== id)
				})
			// Update data on the events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map(event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
			// Update user data
			queryClient.setQueryData(['user'], user => {
			// Remove event from saved list
			user.saves = user.saves.filter( eid => eid!==id)
			// Remove event from attend list
			user.going = user.going.filter( eid => eid!==id)
			// Return user
			return user
			})
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Attend event hook
export function useAttend(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchAttend, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
			// Optimistic update user
			queryClient.setQueryData(['user'], user => {
				// Add event to attend list
				user.going.push(id)
				// Add event to saved list
				user.saves.push(id)
				// Return user
				return user
			})
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Pass event hook
export function useUnAttend(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchUnAttend, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
			// Optimistic update user
			queryClient.setQueryData(['user'], user => {
				// Remove event from attend list
				user.going = user.going.filter( eid => eid!==id)
				// Return user
				return user
			})
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Attend event hook
export function useLike(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchLike, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
			// Optimistic update user
			queryClient.setQueryData(['user'], user => {
				// Add event to likes list
				user.likes.push(id)
				// Return user
				return user
			})
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Pass event hook
export function useUnLike(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.fetchUnLike, {
		onSuccess: (data,id) => {
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
			// Optimistic update user
			queryClient.setQueryData(['user'], user => {
				// Remove event from likes list
				user.likes = user.likes.filter( eid => eid!==id)
				// Return user
				return user
			})
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Add a new post
export function usePost(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Construct query
	return useMutation( ({id, text}) => api.fetchPost(id,text) , {
		onSuccess: (data, input) => {
			// Don't process empty data
			if(!data || !input) return
			// Extract event id
			const id = data._id
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		retry: false,
		...options
	})
}

// Like a post
export function useLikePost(options) {
	// get the query client
	const queryClient = useQueryClient()
	// Construct query
	return useMutation( ({eid,pid}) => api.setPostLike(eid,pid,1), {
		onSuccess: (data, input) => {
			// Don't process empty data
			if(!data || !input) return
			// Extract event id
			const id = data._id
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		retry: false,
		...options
	})
}

// Dislike a post
export function useDislikePost(options) {
	// get the query client
	const queryClient = useQueryClient()
	// Construct query
	return useMutation( ({eid,pid}) => api.setPostLike(eid,pid,-1), {
		onSuccess: (data, input) => {
			// Don't process empty data
			if(!data || !input) return
			// Extract event id
			const id = data._id
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		retry: false,
		...options
	})
}

// Clear (dis)like post
export function useClearLikePost(options) {
	// get the query client
	const queryClient = useQueryClient()
	// Construct query
	return useMutation( ({eid,pid}) => api.setPostLike(eid,pid,0), {
		onSuccess: (data, input) => {
			// Don't process empty data
			if(!data || !input) return
			// Extract event id
			const id = data._id
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		retry: false,
		...options
	})
}


// Add new reply to post
export function useReply(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Construct query
	return useMutation( ({eid, pid, text}) => api.fetchReply(eid,pid,text) , {
		onSuccess: (data, input) => {
			// Don't process empty data
			if(!data || !input) return
			// Extract event id
			const id = data._id
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		retry: false,
		...options
	})
}

// Share event
export function useShare(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Construct query
	return useMutation( async (event) => {
		// Construct event url
		const url = `${api.weburl}/event/${event?._id}`
		// On web
		if(Platform.OS === 'web') {
			// Put url in clipboard
			Clipboard.setString(url)
			// Generate alert
			alert('Event copied to clipboard')
			// Report share
			return await api.fetchShare(event._id, Platform.OS, '')
		}
		// On mobile
		else {
			// Event title
			const title = event?.title || ""
			// Construct message
			const message = `${url}\n\n${title}\n\n${event?.subtitle}\n\n${event?.description}`
			// Share event
			const result = await Share.share({url,title,message},{dialogTitle:title,subject:title})
			// Report share
			if(result.action === Share.sharedAction)
				return await api.fetchShare(event._id, Platform.OS, result.activityType)
			return null
		}
	}, {
		onSuccess: (data,input) => {
			// Don't process empty data
			if(!data || !input) return
			// Extract event id
			const id = input._id
			// Update the data on the event
			queryClient.setQueryData(['event',id], data)
			// Update saved list
			if(queryClient.getQueryState('user/saves'))
				queryClient.setQueryData('user/saves', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update user events list
			if(queryClient.getQueryState('user/events'))
				queryClient.setQueryData('user/events', events => {
					return events?.map( event => (event._id === id)?data:event)
				})
			// Update event feed
			if(queryClient.getQueryState('feed'))
				queryClient.setQueryData('feed', feed => ({
					...feed,
					pages: feed.pages.map(page => ({
						...page, 
						events:page.events.map(event => (event._id === id)?data:event)
					}))
				}))
		},
		// Post process event
		select: event => preProcessEventDynamic(event),
		// Overrides
		...options
	})
}

// Set event reminders
async function setReminders(events) {
	// 1. Clear all reminders
	await clearAllReminders()
	// 2. Set new reminders for events 
	for(var event of events) {
		// Direct event reminder
		reminder(event,'0s', 0)
		// Six hour before event reminder
		reminder(event,'6h', 6*60*60)
	}
}

// Events created by user
export function useUserCreatedEvents(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create query
	return useQuery('user/created', api.fetchUserCreatedEvents, {
		// Refresh every 5 minutes
		staleTime: 5*60*1000,
		// No events means empty array
		placeholderData: [],
		// Sort en memorize data
		select: events => sort(events.map(event => preProcessEventDynamic(event))),
		// Update cache and set reminders
		onSuccess: events => {
			// 1. Update event data
			events?.forEach( event => queryClient.setQueryData(['event', event._id], event) )
			// 2. Set reminders for events
			setReminders(events)
		},
		// Overrides
		...options,
	})
}

// Events user is going to attend
export function useUserGoingEvents(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create query
	return useQuery('user/going', api.fetchUserGoingEvents, {
		// Refresh every 5 minutes
		staleTime: 5*60*1000,
		// No events means empty array
		placeholderData: [],
		// Sort en memorize data
		select: events => sort(events.map(event => preProcessEventDynamic(event))),
		// After fetching events
		onSuccess: events => {
			// 1. Update event data
			events?.forEach( event => queryClient.setQueryData(['event', event._id], event) )
		},
		// Overrides
		...options,
	})
}

// Events saved by user
export function useUserSavedEvents(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create query
	return useQuery('user/saves', api.fetchUserSavedEvents, {
		// Refresh every 5 minutes
		staleTime: 5*60*1000,
		// No events means empty array
		placeholderData: [],
		// Sort en memorize data
		select: events => sort(events.map(event => preProcessEventDynamic(event))),
		// After fetching events
		onSuccess: events => {
			// 1. Update event data
			events?.forEach( event => queryClient.setQueryData(['event', event._id], event) )
			// 2. Set reminders for events
			setReminders(events)
		},
		// Overrides
		...options,
	})
}

export function useUserLikedEvents(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create query
	return useQuery('user/liked', api.fetchUserLikedEvents, {
		// Refresh every 5 minutes
		staleTime: 5*60*1000,
		// No events means empty array
		placeholderData: [],
		// Sort en memorize data
		select: events => sort(events.map(event => preProcessEventDynamic(event))),
		// After fetching events
		onSuccess: events => {
			// 1. Update event data
			events?.forEach( event => queryClient.setQueryData(['event', event._id], event) )
			// 2. Set reminders for events
			setReminders(events)
		},
		// Overrides
		...options,
	})
}

// All user events merged
export function useUserEvents(options) {
	// Get user created events
	const c = useUserCreatedEvents(options)
	// Get user saved events
	const s = useUserSavedEvents(options)
	// Return combination
	return {
		data:[...c.data,...s.data],
		isLoading: c.isLoading || s.isLoading,
		isFetching: c.isFetching || s.isFetching,
		isStale: c.isStale || s.isStale,
		isSuccess: c.isSuccess || s.isSuccess,
		refetch: () => {c.refetch(), s.refetch()},
		remove: () => {c.remove(), s.remove()}
	}
}
