// React
import React, { useRef, useState, useEffect} from 'react'

// React native
import { StyleSheet, ScrollView, View, TouchableOpacity, Platform } from 'react-native'

// Views
import EventFront from 'view/EventFrontView'
import EventBack  from 'view/EventBackView'

// Event component
export default function EventFrontBackView({navigation, route, onScrollLock, ...props}) {

	// ScrollView ref
	const _ScrollView = useRef()

	// Current page
	const [page, setPage] = useState(0)

	// Track page variable
	useEffect(() => {
		setPage(props.page)
		if(props.page == 0)
			_ScrollView.current.scrollTo()
		if(props.page == 1)
			_ScrollView.current.scrollToEnd()
		},[props.page])

	// Render component
	return (
		<ScrollView ref={_ScrollView}
			style={[{widht:props.width,height:props.height},props.style]}
			horizontal
			pagingEnabled
			directionalLockEnabled
			showsVerticalScrollIndicator={false}
			showsHorizontalScrollIndicator={false}
			scrollEventThrottle={250}
			>
			<View>
				<EventFront loggedin={props.loggedin} user={props.user} event={props.event} style={{width:props.width,height:props.height}}/>
				{ Platform.OS === 'web' &&
					<TouchableOpacity 
						style={[styles.nav, styles.frontNav, {
							height:props.height-72,
							cursor:'e-resize',
						}]}
						onPress={() => {
							_ScrollView.current.scrollToEnd()
							setPage(1)
						}}
						navigation={navigation}
					/>
				}
			</View>
			<View>
				<EventBack 
					navigation={navigation}
					loggedin={props.loggedin} 
					user={props.user} 
					event={props.event} 
					style={{width:props.width,height:props.height}}
				/>
				{ Platform.OS === 'web' &&
					<TouchableOpacity 
						style={[styles.nav, styles.backNav, {
							height:props.height-72,
							cursor:'w-resize',
						}]}
						onPress={() => {
							_ScrollView.current.scrollTo()
							setPage(0)
						}}
					/>
				}
			</View>
		</ScrollView>
	)
}

const styles = StyleSheet.create({
	frontNav:{
		top:0,
		right:0,
		height:'100%',
		// justifyContent:'right',
		paddingRight:10,
		borderRightWidth: (Platform.OS === 'web')?3:0,
		borderColor:'#000',
	},
	backNav:{
		top:0,
		left:0,
		height:'80%',
		// justifyContent:'left',
		paddingLeft:10,
		borderLeftWidth: (Platform.OS === 'web')?3:0,
		borderColor:'#000',
	},
	nav: {
		flex:1,
		flexDirection:'row',
		position: 'absolute',
		// alignItems:'top',
		// alignContent:'right',
		width:'25%',
		paddingTop:10,
		// cursor:'move',
		// backgroundColor: 'red',
	}, 
	navicon: {
	}
})
