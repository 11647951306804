import React, { useState, useEffect, createElement } from 'react'
import { Platform, StyleSheet, View} from 'react-native'

// Typography
import { Paragraph } from 'style/typography'

// React native elements
import { Button, Icon } from 'react-native-elements'

// Date and time picker
import DateTimePicker from '@react-native-community/datetimepicker'

// Modal
import Modal from 'component/Modal'

// Component
export default function DatePicker(props) {

	// Date object
	const [date, setDate ] = useState()

	// Track date
	useEffect(() => setDate(props.date?new Date(props.date):undefined), [props.date])

	// Show modals
	const [showModal,setShowModal] = useState(false)


	// Web component
	const web = (
		<View style={styles.dateContainer}>
			<Button
				{...props}
				type='clear'
				icon={
					<View style={styles.buttonContainer}>
						<Icon name="calendar" size={18} color={date?'white':'grey'} type='antdesign'/>
						{Platform.OS === 'web' && createElement('input',{
							type: 'date',
							value: date?.toISOString().split('T')[0] || "",
							min: new Date().toISOString().split('T')[0],
							onInput: (event) => props.onChange(event.target.value && new Date(event.target.value)),
							style: {
								color:'white',
								backgroundColor:'transparent',
								borderWidth:0
							}
						})}

					</View>
				}
				onPress={() => setShowModal(true)}
			/>
		</View>
	)

	// Mobile component
	const mobile = (
		<View style={styles.dateContainer}>
			<Button
				{...props}
				type='clear'
				icon={
					<View style={styles.buttonContainer}>
						<Icon name="calendar" size={18} color={date?'white':'grey'} type='antdesign'/>
						<Paragraph> {date?date?.toLocaleDateString():'-'}</Paragraph>
					</View>
				}
				onPress={() => setShowModal(true)}
			/>

			{ showModal && 
				<DateTimePicker
					minimumDate={new Date()}
					value={date || new Date()}
					mode='date'
					onChange={(_,selectedDate) => {
						// Hide modal
						setShowModal(false)
						// Trigger change event
						if(selectedDate)
						props.onChange(selectedDate)
					}}
			/>
			}
		</View>
	)

	// Return web or mobile version
	return (Platform.OS === 'web')?web:mobile
}

// Styles
const styles = StyleSheet.create({
	dateContainer:{ 
		width:'100%',
		flexDirection:'row',
		alignItems:'center',
		justifyContent: 'space-between',
		paddingHorizontal:10,
	},
	buttonContainer:{
		flexDirection:'row',
		alignItems:'center',
	},
	title:{
		fontWeight:'bold',
	},
	duration:{
		fontSize: 19,
		alignItems:'center',
		justifyContent:'center',
	}
})
