import { useEffect, useMemo, useCallback } from 'react'
import { Alert } from 'react-native'
import Toast from 'react-native-toast-message'
import { useMutation, useQuery, useQueryClient } from 'react-query'

// Events hook
import { useEvents } from 'hook/events'

// Reminder notification
import { reminder, clearAllReminders } from 'notification/reminder'

// Import api
import api from 'api'

// Authentication hooks
import {useLoggedin} from 'hook/authentication'

// Use the user
export function useUser(id,options) {
	// Check if user loggedin
	const {data:loggedin, isFetching} = useLoggedin()
	// Query
	return useQuery(['user', id].filter(Boolean), () => api.fetchUser(id), {
		enabled: !isFetching && loggedin,
		staleTime: 5*60*1000,
		...options
	})
}

// Get multiple users
export function useUsers(ids, options) {
	// Query
	return useQuery(['users', ids], () => api.fetchUsers(ids), {
		staleTime: 4*60*60*1000,
		...options
	})
}

// Search for users
export function useUserSearch(options) {
	// Mutation
	return useMutation(api.fetchUserSearch, options)
}

// Set avatar
export function useAvatar(options) {
	// Get the query client
	const queryClient = useQueryClient()
	// Create mutation
	return useMutation(api.patchAvatar, {
		onSuccess: (data) => {
			// Update the data on the event
			queryClient.setQueryData('user', data)
			// Invalidate the user query
			queryClient.invalidateQueries('user')
		},
		...options
	})
}

