import React, {useState,useEffect,useRef} from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import { SearchBar, Button, Icon } from 'react-native-elements'

// Authentication hooks
import {useLoggedin} from 'hook/authentication'

// User hook
import { useUser } from 'hook/user'

// Event feed hook
import {useFeed} from 'hook/feed'

// Hook to get device location
import {useLocation} from 'hook/location'

// Views
import EventMap from 'view/EventMap'
import EventCards from 'view/EventCards'

// Header search bar
function HeaderSearchBar(props) {
	
	// Extract event functions
	const {onSearch, onButton} = props

	// Search query
	const [query, setQuery] = useState('')

	// Loading indicator
	const [loading, setLoading] = useState(false)

	// Track loading
	useEffect(() => {setLoading(props.loading)},[props.loading])

	// Disabled
	const [disabled, setDisabled] = useState(false)

	// Track disabled
	useEffect(() => {setDisabled(props.disabled)},[props.disabled])

	// Toggle
	const [toggle, setToggle] = useState(false)

	// Track toggle
	useEffect(() => {setToggle(props.toggle)},[props.toggle])

	// Render component
	return (<View style={styles.TopContainer}>
		<SearchBar 
			placeholder='search'
			style={styles.SearchBar}
			containerStyle={styles.SearchBarContainer}
			inputContainerStyle={styles.SearchBarInside}
			labelStyle={styles.SearchBarInside}
			leftIconContainerStyle={styles.SearchBarInside}
			inputStyle={styles.SearchBarInside}
			rightIconContainerStyle={styles.SearchBarInside}
			errorStyle={styles.SearchBarInside}
			disabledInputStyle={styles.SearchBarInside}
			value={query}
			disabled={loading || disabled}
			// showLoading={loading}
			loadingProps={{ animating: true, color: 'grey'}}
			onChangeText={text => {setQuery(text)}}
			onSubmitEditing={() => onSearch(query)}
			onBlur={() => onSearch(query)}
			onClear={() => {
				onSearch('')
				setQuery('')
			}}
		/>
		<Button
			buttonStyle={styles.TopButton}
			type="clear"
			disabled={disabled}
			loading={loading}
			icon={<Icon name={toggle?"chevrons-up":'plus'} type='feather' size={21} color='white'/>}
			onPress={() => onButton()}
		/>
	</View>)
}

// Feed screen
export default function EventFeed({navigation}) {

	// FlatList reference
	const _cards = useRef()

	// Event view toggle
	const [toggle, setToggle] = useState(true)

	// Loggedin
	const { data:loggedin } = useLoggedin()

	// User hook
	const { data:user } = useUser()

	// Search query
	const [search, setSearch] = useState('')

	// Event feed hook
	const { data:feed, fetchNextPage, isFetching, isLoading, remove:clearFeed, refetch:feedRefetch } = useFeed(search,20)

	// Track search query
	useEffect(() => {clearFeed();feedRefetch()},[search])

	// Device location
	const {data:device} = useLocation()

	// Set navigation options
	useEffect(()=>{
		navigation?.setOptions({
			headerShown: true,
			title: 'Feed',
			header: () => (<HeaderSearchBar 
				toggle={toggle}
				loading={isLoading || isFetching} 
				onSearch={query => {setSearch(query)}}
				onButton={() => toggle?_cards.current.scrollToIndex({index:0}):fetchNextPage()}
			/>)
	})},[isLoading,isFetching,toggle])

	// Cards
	const cards = (<EventCards
		navigation={navigation}
		_FlatList={_cards}
		loggedin={loggedin}
		user={user}
		events={feed}
		// loading={isLoading || isFetching}
		onRefresh={() => {feedRefetch()}}
		onNext={fetchNextPage}
	/>)

	// Map
	const map = (<EventMap
		navigation={navigation}
		user={user}
		events={feed}
		device={device}
	/>)

	// Render screen
	return (<View style={styles.container}>
		{ toggle && cards || map }
		{ Platform.OS !== 'web' && <Button
			type='solid'
			containerStyle={styles.toggle}
			icon={<Icon name={toggle?"grid":"map-sharp"} type='ionicon' size={21} color='white'/>}
			onPress={() => setToggle(!toggle)}
		/>}
	</View>)
}

// Styles
const styles = StyleSheet.create({
	toggle: {
		position:'absolute',
		top:0,
		right:0,
		margin:7,
	},
	container: {
		flex:1
	},
	mapCardContainer: {
		position:'absolute',
		width:'100%',
		height:300,
		bottom:50,
		borderTopWidth: 0,
		borderBottomWidth: 0,
		borderBottomColor: 'grey',
		borderTopColor: 'grey',
	},
	TopContainer: {
		flexDirection: 'row',
		backgroundColor:'black',
		alignItems: 'center',
		alignContent: 'center',
	},
	SearchBar: {
		padding:0,
		margin:0,
	},
	SearchBarContainer: {
		flex:1,
		height: 46,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor:'black',
		borderWidth:0,
		padding:0,
		marginVertical:6,
		marginLeft:6,
	},
	SearchBarInside:{
		margin:0,
		padding:0,
	},
	TopButton: {
		flex:1,
		backgroundColor:'black',
		height:46,
		width:50,
		padding:10 
	},
	FlatList: {
		height:'100%',
	},
	FlatListContainer: {},
})



